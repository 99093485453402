import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getAssetCategories } from "@services/index.service";
import { AssetCategoriesSetupRes } from "@_types/setups";

const useGetAssetCategories = (companyId: number) => {
  return useQuery<number, unknown, AssetCategoriesSetupRes>({
    queryKey: [QUERY_KEYS.GET_ASSET_CATEGORIES, companyId],
    queryFn: () => getAssetCategories(companyId),
    enabled: !!companyId,
  });
};

export default useGetAssetCategories;
