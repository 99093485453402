import {
  Box,
  Input,
  Button,
  Icon,
  DatePicker,
  Select,
} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { WorkQueueFilterProps } from "./work-queue-filter.props";
import { SortOrder } from "@helpers/enum";
import { convertDateToISOString } from "@helpers/utils";
import { DEFAULT_PAGE_NUMBER } from "@helpers/const";
const WorkQueueFilter = <SearchParams extends { [key: string]: any }>({
  searchParams,
  setSearchParams,
  searchKey,
  placeholder,
  sortParams,
  setSortParams,
  type = "text",
  statuses,
}: WorkQueueFilterProps<SearchParams>) => {
  const theme = useTheme();
  const handleSortParamsChange = (key: keyof SearchParams) => {
    if (!key || key !== sortParams.sort_by) {
      setSortParams({ sort_by: key, sort_order: SortOrder.ASC });
      return;
    }
    if (sortParams.sort_order === SortOrder.DESC) {
      setSortParams({ sort_by: null, sort_order: SortOrder.ASC });
      return;
    }
    setSortParams({
      ...sortParams,
      sort_order: SortOrder.DESC,
    });
  };

  const getArrowIcon = (
    key: keyof SearchParams
  ): "DescendingIcon" | "AscendingIcon" | "ArrowUpDown" => {
    if (sortParams.sort_by == key) {
      return (
        {
          [SortOrder.ASC]: "AscendingIcon",
          [SortOrder.DESC]: "DescendingIcon",
        } as const
      )[sortParams.sort_order];
    }
    return "ArrowUpDown";
  };

  return (
    <Box theme={theme} className="table-filter">
      {searchKey === "updated_at" ? (
        <DatePicker
          theme={theme}
          value={searchParams.updated_at}
          onChange={(e: Date) => {
            setSearchParams({
              ...searchParams,
              page_number: DEFAULT_PAGE_NUMBER,
              updated_at: convertDateToISOString(e),
            });
          }}
          inputFormat="DD/MM/YYYY"
          uppercaseLabel
          disablePortal={false}
          sameDateSelection={true}
        />
      ) : searchKey === "date_submitted" ? (
        <DatePicker
          theme={theme}
          value={searchParams.date_submitted}
          onChange={(e: Date) => {
            setSearchParams({
              ...searchParams,
              page_number: DEFAULT_PAGE_NUMBER,
              date_submitted: convertDateToISOString(e),
            });
          }}
          inputFormat="DD/MM/YYYY"
          uppercaseLabel
          disablePortal={false}
          sameDateSelection={true}
        />
      ) : searchKey === "statuses" ? (
        <Select
          theme={theme}
          items={statuses}
          disablePortal={false}
          value={
            searchParams.statuses.length === statuses.length - 1
              ? "all"
              : searchParams.statuses[0]
          }
          onChange={(e) => {
            const value = e.target.value;
            if (value === "all") {
              setSearchParams({
                ...searchParams,
                page_number: DEFAULT_PAGE_NUMBER,
                [searchKey]: statuses
                  .map((status) => {
                    if (status.value !== "all") return status.value;
                  })
                  .filter(Boolean),
              });
              return;
            }
            setSearchParams({
              ...searchParams,
              page_number: DEFAULT_PAGE_NUMBER,
              [searchKey]: [e.target.value],
            });
          }}
        />
      ) : (
        <Input
          theme={theme}
          fullWidth={true}
          type={type}
          startAdornment=""
          endAdornment=""
          id=""
          placeholder={!!placeholder ? placeholder : "Search"}
          value={searchParams[searchKey]}
          onChange={(value: string) => {
            setSearchParams({
              ...searchParams,
              page_number: DEFAULT_PAGE_NUMBER,
              [searchKey]: value,
            });
          }}
        ></Input>
      )}

      <Button
        defaultBtn
        iconText={<Icon name={getArrowIcon(searchKey)} />}
        onClick={() => handleSortParamsChange(searchKey)}
      ></Button>
    </Box>
  );
};

export default WorkQueueFilter;
