import { useGetProductTypes } from "@hooks/queries";

type ProductType = {
    is_active: boolean;
    is_deleted: boolean;
    id: number;
    code: string;
    name: string;
}

const useGetProductType = (productCode : string): string => {
    const {
        data: productTypes,
      } = useGetProductTypes();

    if(productTypes) {
        return productTypes.find((pt: ProductType) => pt.code === productCode).name
    }
      
    return undefined
  };
  
  export default useGetProductType;