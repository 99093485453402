import React, { useEffect } from "react";
import { Button, Dialog, Textarea } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { AddCommentDialogWrap } from "./add-comment-dialog.style";
import { TCommentsDetails } from "@_types";
import { AddCommentDialogProps } from "./add-comment-dialog.props";
import { CommentCategory } from "@helpers/enum";
import { getUserSettings } from "@helpers/utils";

const AddCommentDialog = ({
  open,
  onClose,
  onCommentAdd,
  commentToEdit,
}: AddCommentDialogProps) => {
  const theme = useTheme();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<TCommentsDetails>({
    defaultValues: {
      comment: commentToEdit?.comment || "",
    },
  });

  useEffect(() => {
    if (open && commentToEdit) {
      reset({ comment: commentToEdit.comment || "" });
    } else {
      reset({ comment: "" });
    }
  }, [commentToEdit, open, reset]);

  const onSubmit = (data: TCommentsDetails) => {
    const userName = getUserSettings()?.user_name || "";

    onCommentAdd({
      ...data,
      category: CommentCategory.UNDERWRITING,
      createdBy: commentToEdit ? null : userName,
      updatedBy: commentToEdit ? userName : null,
      identifier: commentToEdit?.identifier,
      orderReferenceId: commentToEdit?.orderReferenceId,
      customerId: commentToEdit?.customerId,
    });

    reset();
    onClose();
  };

  const dialogText = commentToEdit ? "Edit Comment" : "Add Comment";

  return (
    <Dialog
      theme={theme}
      size={"sm"}
      onCloseDialog={onClose}
      open={open}
      title={dialogText}
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Cancel"
            onClick={onClose}
          />
          <Button
            theme={theme}
            type="button"
            primary
            text="Post Comment"
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }
    >
      <AddCommentDialogWrap theme={theme}>
        <Controller
          name="comment"
          control={control}
          rules={{
            required: "Comment is required",
            validate: (value) =>
              value.trim().length > 0 || "Comment cannot be empty",
          }}
          render={({ field }) => (
            <>
              <Textarea
                theme={theme}
                placeholder="Type here..."
                label={`${dialogText} *`}
                fullWidth
                type="text"
                id={"textAreaId"}
                rows={3}
                {...field}
              />
              {errors.comment && (
                <span style={{ color: "red" }}>{errors.comment.message}</span>
              )}
            </>
          )}
        />
      </AddCommentDialogWrap>
    </Dialog>
  );
};

export default AddCommentDialog;
