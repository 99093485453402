import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const SettingDialogContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.settings-dialog-content": {
    ".setting-item": {
      marginTop: 16,
      "&:first-child": {
        marginTop: 0,
      },
      [theme.breakpoints.down("md")]: {
        marginTop: 32,
      },
    },
  },
}));
