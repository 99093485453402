import { StepperProgress } from "@components";
import {
  ProposalSummary,
  AssetDetails,
  ProposalDetails,
  CreditDocuments,
  FinanceDetails,
  ProposalFooter,
} from "@components/proposal";
import { useTheme } from "@mui/material";
import ProposalPageHeader from "@components/proposal/page-header/page-header.component";
import { useState, useEffect, useMemo } from "react";
import { Box, Button, Icon } from "@ntpkunity/controls";
import { zodResolver } from "@hookform/resolvers/zod";
import { ProposalSchema } from "@models";
import { PROPOSAL_STEP_ITEMS_INITIAL_STATE } from "@helpers/const";
import { TProposalForm } from "@_types";
import {
  useForm,
  useFieldArray,
  FormProvider,
  DefaultValues,
} from "react-hook-form";
import { ProposalFieldArraysProvider } from "@contexts/proposal-form";
import { ProposalProps } from "./proposal.props";
import {
  useClientTypesSetup,
  useProposalDetailsSetups,
  useProposalStepItems,
} from "@hooks/proposal";

import {
  CalculationResults,
  CalculationsContextProvider,
  QuotationFormProvider,
  useCalculationsActionsContext,
} from "@ntpkunity/controls-common";
import { OrderStatus, ProposalTabs } from "@helpers/enum";

export const Proposal = ({
  proposalData,
  proposalSummaryPageReference,
  creditDocumentPageReference,
}: ProposalProps) => {
  const isEditMode = !!proposalData;
  const clientTypes = useClientTypesSetup();

  const { productTypes } = useProposalDetailsSetups();

  const PROPOSAL_FORM_INITIAL_STATE: DefaultValues<TProposalForm> = {
    status: OrderStatus.DRAFT,
    proposalDetails: {
      productType: productTypes.at(0).code,
    },
    customerDetails: {
      clientType: clientTypes.length ? clientTypes.at(0).code : undefined,
      directorDetails: [],
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        zipCode: "",
        county: "",
      },
    },
  };

  const formMethods = useForm<TProposalForm>({
    defaultValues: useMemo(
      () => (isEditMode ? proposalData.proposal : PROPOSAL_FORM_INITIAL_STATE),
      [clientTypes.length]
    ),
    resolver: zodResolver(ProposalSchema),
  });

  const directorFieldArrayMethods = useFieldArray({
    control: formMethods.control,
    name: "customerDetails.directorDetails",
  });

  const assetFieldArrayMethods = useFieldArray({
    control: formMethods.control,
    name: "assets",
  });

  const documentsFieldArrayMethods = useFieldArray({
    control: formMethods.control,
    name: "documents",
  });

  const {
    activeIndex,
    setActiveIndex,
    stepItems,
    goToNextTab,
    goToPrevTab,
    getNextButtonText,
  } = useProposalStepItems({ watch: formMethods.watch });
  const [proposalSubmitted, setProposalSubmitted] = useState(false);

  useEffect(() => {
    if (proposalSummaryPageReference) {
      setActiveIndex(PROPOSAL_STEP_ITEMS_INITIAL_STATE.length - 1);
    } else if (creditDocumentPageReference)
      setActiveIndex(PROPOSAL_STEP_ITEMS_INITIAL_STATE.length - 2);
  }, []);

  return (
    <>
      <CalculationsContextProvider>
        <QuotationFormProvider
          defaultValues={proposalData?.quotation?.formValues}
        >
          <FormProvider {...formMethods}>
            <ProposalFieldArraysProvider
              value={{
                directorMethods: directorFieldArrayMethods,
                assetMethods: assetFieldArrayMethods,
                documentMethods: documentsFieldArrayMethods,
              }}
            >
              <ProposalPageHeader
                isLastStep={
                  activeIndex + 1 == PROPOSAL_STEP_ITEMS_INITIAL_STATE.length
                }
                showActionBtn={!proposalSubmitted}
              />
              <ProposalStepperProgress
                calculations={proposalData?.quotation?.calculations}
                proposalSubmitted={proposalSubmitted}
                stepItems={stepItems}
                setActiveIndex={setActiveIndex}
                activeIndex={activeIndex}
                goToPrevTab={goToPrevTab}
                getNextButtonText={getNextButtonText}
              />

              {!proposalSubmitted && (
                <ProposalFooter
                  activeIndex={activeIndex}
                  onNextButtonClick={goToNextTab}
                  onPrevButtonClick={goToPrevTab}
                  setProposalSubmitted={setProposalSubmitted}
                  getNextButtonText={getNextButtonText}
                />
              )}
            </ProposalFieldArraysProvider>
          </FormProvider>
        </QuotationFormProvider>
      </CalculationsContextProvider>
    </>
  );
};

const ProposalStepperProgress = ({
  calculations,
  proposalSubmitted,
  stepItems,
  setActiveIndex,
  activeIndex,
  goToPrevTab,
  getNextButtonText,
}: {
  calculations: CalculationResults;
  proposalSubmitted: boolean;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  stepItems: {
    title: ProposalTabs;
    disabled: boolean;
  }[];
  goToPrevTab: () => void;
  getNextButtonText: () => string;
}) => {
  const theme = useTheme();

  const { setCalculationResults } = useCalculationsActionsContext();
  useEffect(() => {
    if (calculations) {
      setCalculationResults(calculations);
    }
  }, []);

  return (
    <>
      <StepperProgress
        stepItems={stepItems}
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        onPrevButtonClick={goToPrevTab}
        stepTitle="Proposal Details"
        nextStepTitle={getNextButtonText()}
        hideStepItems={proposalSubmitted}
        actionArea={
          <>
            <Button
              defaultBtn
              theme={theme}
              onClick={() => {}}
              iconText={<Icon name="SaveDiskIcon" />}
            />
          </>
        }
      >
        <ProposalDetails />
        <AssetDetails />
        <Box
          theme={theme}
          className="quote-container"
          m={{ xs: 0, md: "-16px -40px -16px -40px" }}
        >
          <FinanceDetails />
        </Box>
        <CreditDocuments />
        <ProposalSummary proposalSubmitted={proposalSubmitted} />
      </StepperProgress>
    </>
  );
};
