import { useProposalFieldArrays } from "@contexts/proposal-form";
import { FormProvider, useForm } from "react-hook-form";
import { TAssetDetails } from "@_types";
import { AssetDetailsForm } from "@components/proposal/asset-details";
import { EditAssetFormProps } from "./edit-asset-form.props";
import {
  QuotationForm,
  useQuotationFormContext,
  useConfigurationActionContext,
} from "@ntpkunity/controls-common";
import { useSumOfAssetCost } from "@hooks/proposal";

const EditAssetForm = ({ editAssetId, setEditAssetId }: EditAssetFormProps) => {
  const {
    assetMethods: { update, fields: assets },
  } = useProposalFieldArrays();
  const { resetAssetCost } = useConfigurationActionContext();
  const { watch: watchQuotationForm, setValue: setValueQuotationForm } =
    useQuotationFormContext<QuotationForm>();
  const sumOfAssetCost = useSumOfAssetCost();
  const assetToUpdate = assets.find((asset) => asset.id === editAssetId);
  const formMethods = useForm<TAssetDetails>({
    defaultValues: { ...assetToUpdate },
  });

  const { watch } = formMethods;
  const totalCost = watch("totalCost");

  const handleSaveAsset = () => {
    const indexOfAssetToEdit = assets.findIndex(
      (director) => director.id === editAssetId
    );
    update(indexOfAssetToEdit, {
      ...formMethods.getValues(),
      totalCost: totalCost,
    });
    const oldAssetCost = assets[indexOfAssetToEdit].totalCost;
    const updatedSumOfAssetCost = sumOfAssetCost - oldAssetCost + totalCost;
    resetAssetCost(updatedSumOfAssetCost);
    setValueQuotationForm("assetCost", updatedSumOfAssetCost);
    formMethods.reset();
    setEditAssetId(null);
  };

  return (
    <FormProvider {...formMethods}>
      <AssetDetailsForm
        handleSaveAsset={handleSaveAsset}
        isEditMode
        setEditAssetId={setEditAssetId}
      />
    </FormProvider>
  );
};

export default EditAssetForm;
