import { Footer } from "@components";
import { useSnackbarContext } from "@contexts/snackbar";
import { OrderStatus } from "@helpers/enum";
import { useSaveProposal, useUpdateProposal } from "@hooks/mutations";
import { useGetSaveProposalPayload } from "@hooks/proposal";
import { useParams } from "react-router-dom";
import { PROPOSAL_STEP_ITEMS_INITIAL_STATE } from "@helpers/const";
import { ProposalFooterProps } from "./proposal-footer.props";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types/forms";

const ProposalFooter = ({
  activeIndex,
  onNextButtonClick,
  onPrevButtonClick,
  setProposalSubmitted,
  getNextButtonText,
}: ProposalFooterProps) => {
  const params = useParams<{ proposalId?: string }>();
  const isEditMode = params.proposalId;
  const { setValue } = useFormContext<TProposalForm>();
  const { getPayload, validateProposalPayload } = useGetSaveProposalPayload(
    params.proposalId
  );
  const { mutateAsync: saveProposal } = useSaveProposal();
  const { mutateAsync: updateProposal } = useUpdateProposal();
  const { setSnackbar } = useSnackbarContext();

  const mutateProposal = isEditMode ? updateProposal : saveProposal;
  const isFirstStep = activeIndex === 0;
  const isLastStep =
    activeIndex + 1 === PROPOSAL_STEP_ITEMS_INITIAL_STATE.length;

  const handleSubmitProposal = async () => {
    setSnackbar({ open: true, message: "Submitting Proposal" });
    const payload = getPayload(OrderStatus.SUBMITTED);

    if (!validateProposalPayload(payload)) return;

    try {
      const proposal = await mutateProposal(payload);
      setValue("identifier", proposal.identifier);
      setSnackbar(
        { open: true, message: "Proposal Submitted Successfully" },
        2000
      );
      setProposalSubmitted(true);
    } catch (err) {
      setSnackbar({
        open: true,
        message: "There was an error while submitting proposal",
      });
    }
  };

  const handleNextButton = () =>
    isLastStep ? handleSubmitProposal() : onNextButtonClick();

  return (
    <Footer
      actionButtonText={getNextButtonText()}
      actionButtonOnClick={handleNextButton}
      showBackButton={!isFirstStep}
      backButtonOnClick={onPrevButtonClick}
    />
  );
};

export default ProposalFooter;
