import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTheme } from "@mui/material";
import { BarChartWrap, CustomTooltipWrap } from "./bar-chart.style";
import { Box, Typography } from "@ntpkunity/controls";

type BarChartControlProps = {
  chartData: any[];
  layout: "horizontal" | "vertical" | "centric" | "radial";
  xAxisDataKey?: string;
  xAxisType?: "number" | "category" | undefined;
  yAxisDataKey?: string;
  yAxisType?: "number" | "category" | undefined;
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length && label) {
    console.log(payload);
    const theme = useTheme();
    return (
      <CustomTooltipWrap className="custom-tooltip-wrap" theme={theme}>
        <Typography
          theme={theme}
          component="small"
          variant="caption"
          display={"block"}
        >
          {label}
          {label?.includes("Days") ? "" : " Days"}
        </Typography>
        <Typography
          theme={theme}
          component="small"
          variant="caption"
          display={"flex"}
          alignItems={"center"}
          gap={1}
        >
          <span className="ledger-indicator"></span>
          <span className="ledger-value">
            {payload[0].name}: {payload[0].value}
          </span>
        </Typography>
      </CustomTooltipWrap>
    );
  }
};

export const ProposalBarChart = ({
  chartData,
  layout,
  xAxisDataKey,
  xAxisType,
  yAxisDataKey,
  yAxisType,
}: BarChartControlProps) => {
  const theme = useTheme();

  return (
    <BarChartWrap theme={theme} className="bar-chart-wrap">
      <ResponsiveContainer width="100%" height="100%" maxHeight={270}>
        {chartData && (
          <BarChart
            width={270}
            height={270}
            data={chartData}
            layout={layout}
            barSize={10}
            margin={{
              top: 40,
              bottom: 6,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            {xAxisDataKey && xAxisType && (
              <XAxis
                dataKey={xAxisDataKey}
                type={xAxisType}
                axisLine={true}
                tickMargin={16}
              />
            )}
            {yAxisDataKey && yAxisType && (
              <YAxis dataKey={yAxisDataKey} type={yAxisType} />
            )}
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey={yAxisDataKey} fill={theme.palette.primary.main}>
              <LabelList
                position="top"
                offset={24}
                className="label-text"
                fill={theme.palette.grey[900]}
              />
            </Bar>
          </BarChart>
        )}
      </ResponsiveContainer>
    </BarChartWrap>
  );
};
