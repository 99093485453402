import { useTheme } from "@mui/material";
import { Button, Dialog, ScrollableTabs } from "@ntpkunity/controls";
import { CompanyHouseDialogContent } from "./company-lookup-dialog.style";
import { CompaniesByRegNum } from "../companies-by-reg-num/companies-by-reg-num.component";
import { CompaniesByName } from "../companies-by-name/companies-by-name.component";
import { useState } from "react";
import {
  CompaniesByNameResponse,
  CompaniesByRegNumResponse,
  CompanySearchReqParams,
  TProposalForm,
} from "@_types";
import { useSearchCompanies } from "@hooks/queries";
import { CompaniesLookupTabs } from "@helpers/enum";
import { useFormContext } from "react-hook-form";
import { getNumberOfYearsPassed } from "@helpers/utils";
import { useProposalFieldArrays } from "@contexts/proposal-form";
import { searchCompanies } from "@services/hubex.service";
import { AxiosError } from "axios";

type CompanyLookupDialogProps = {
  open: boolean;
  closeDialog: () => void;
};

const SEARCH_PARAMS_INITIAL_STATE = {
  query: "",
  registration_number: "",
};
const NAME_TAB_INDEX = 0;

export const CompanyLookupDialog = ({
  open,
  closeDialog,
}: CompanyLookupDialogProps) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useState<CompanySearchReqParams>(
    SEARCH_PARAMS_INITIAL_STATE
  );
  const [companies, setCompanies] = useState<
    CompaniesByNameResponse[] | CompaniesByRegNumResponse[]
  >([]);
  const [selectedCompany, setSelectedCompany] = useState<
    CompaniesByNameResponse | CompaniesByRegNumResponse
  >();
  const { setValue } = useFormContext<TProposalForm>();
  const {
    directorMethods: { append: appendDirector, remove: removeDirector },
  } = useProposalFieldArrays();

  const {
    isLoading: companiesLoading,
    isError: errorInCompanies,
    error,
    isSuccess: companiesLoaded,
  } = useSearchCompanies(
    process.env.X_SERVICE_PROVIDER,
    searchParams,
    (data: CompaniesByNameResponse[]) => {
      setCompanies(data);
      if (data.length === 1) {
        setSelectedCompany(data.at(0));
      }
    }
  );

  const onTabChange = (_, newIndex: number) => {
    const isNameTab = newIndex === NAME_TAB_INDEX;
    if (isNameTab) {
      setSearchParams({ ...searchParams, registration_number: "" });
    } else {
      setSearchParams({ ...searchParams, query: "" });
    }
    setCompanies([]);
    setSelectedCompany(undefined);
  };

  const _closeDialog = () => {
    setSearchParams(SEARCH_PARAMS_INITIAL_STATE);
    setCompanies([]);
    setSelectedCompany(undefined);
    closeDialog();
  };

  const isSearchByRegNum = (
    company: CompaniesByNameResponse | CompaniesByRegNumResponse
  ): company is CompaniesByRegNumResponse => {
    return Array.isArray(company.sic_code);
  };

  const populateDirectorDetails = (company: CompaniesByRegNumResponse) => {
    removeDirector();
    if (!company) return;
    company.directors_information?.forEach((director) => {
      const nameSplit = director.name.split(" ");
      const firstName = nameSplit.at(0);
      const lastName = nameSplit.length > 1 ? nameSplit.at(1) : "";
      appendDirector({
        address: director.address,
        contactNumber: director.contact_number,
        firstName: firstName,
        lastName: lastName,
        emailAddress: "",
      });
    });
  };

  const handleAddCompany = async () => {
    setValue("customerDetails.companyName", selectedCompany.company_name);
    setValue("customerDetails.companyRegNum", selectedCompany.company_number);
    if (isSearchByRegNum(selectedCompany)) {
      setValue("customerDetails.sicCode", selectedCompany.sic_code?.at(0));
      populateDirectorDetails(selectedCompany);
    } else {
      const company: CompaniesByRegNumResponse[] = await searchCompanies(
        process.env.X_SERVICE_PROVIDER,
        {
          registration_number: selectedCompany.company_number,
        }
      );
      setValue("customerDetails.sicCode", company.at(0)?.sic_code?.at(0));
      populateDirectorDetails(company.at(0));
    }
    setValue(
      "customerDetails.yearsInBusiness",
      getNumberOfYearsPassed(
        new Date(selectedCompany.registration_date)
      ).toString()
    );
    _closeDialog();
  };

  const errorMessage = ((error as AxiosError)?.response?.data as any)?.detail
    ?.message;

  return (
    <Dialog
      theme={theme}
      size="sm"
      open={open}
      title="Lookup From Companies House"
      onCloseDialog={_closeDialog}
      noFooter={!companiesLoaded}
      customFooter={
        companiesLoaded && (
          <>
            <Button
              theme={theme}
              secondary
              text="Cancel"
              type="button"
              onClick={_closeDialog}
            />
            <Button
              theme={theme}
              primary
              text="Add Details"
              type="button"
              disabled={!selectedCompany}
              onClick={handleAddCompany}
            />
          </>
        )
      }
    >
      <CompanyHouseDialogContent
        theme={theme}
        className="company-house-dialog-content"
      >
        <ScrollableTabs
          theme={theme}
          onChange={onTabChange}
          scrollButtons="auto"
          tabValue={1}
          items={[
            {
              title: CompaniesLookupTabs.BY_NAME,
              content: (
                <>
                  <CompaniesByName
                    companiesLoading={companiesLoading}
                    errorInCompanies={errorInCompanies}
                    errorMessage={errorMessage}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    companies={companies as CompaniesByNameResponse[]}
                    selectedCompany={selectedCompany as CompaniesByNameResponse}
                    setSelectedCompany={setSelectedCompany}
                  />
                </>
              ),
            },
            {
              title: CompaniesLookupTabs.BY_REG_NUM,
              content: (
                <>
                  <CompaniesByRegNum
                    companiesLoading={companiesLoading}
                    errorInCompanies={errorInCompanies}
                    companies={companies as CompaniesByRegNumResponse[]}
                    searchParams={searchParams}
                    errorMessage={errorMessage}
                    setSearchParams={setSearchParams}
                    selectedCompany={
                      selectedCompany as CompaniesByRegNumResponse
                    }
                    setSelectedCompany={setSelectedCompany}
                  />
                </>
              ),
            },
          ]}
          defaultTabIndex={0}
        />
      </CompanyHouseDialogContent>
    </Dialog>
  );
};
