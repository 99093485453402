import "react";
import {
  Accordion,
  Box,
  Button,
  Icon,
  Menu,
  Switch,
} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { AddSignatoriesListWrap } from "./add-signatories.style";
import SignatoriesTable from "./signatories-table.component";

const SignatoriesList = () => {
  const theme = useTheme();
  return (
    <AddSignatoriesListWrap theme={theme} className="add-signatories-list-wrap">
      <Box
        theme={theme}
        className="collapse-wrap list-collapse with-border"
        mt={2}
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <>
                  <SignatoriesTable />
                </>
              ),
              title: (
                <>
                  <span className="title-with-icon">
                    <Icon name="DocumentIcon" />
                  </span>
                  Customer Consent Document
                </>
              ),
              actionBtn: (
                <Box
                  theme={theme}
                  display={"flex"}
                  alignItems={"center"}
                  gap={2}
                >
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View/Edit",
                        optionkey: "view-edit",
                        optionValue: "0",
                      },
                      {
                        optionText: "Delete",
                        optionkey: "delete",
                        optionValue: "1",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </Box>
              ),
              key: "string",
              isExpanded: true,
              id: "string",
              ariaControls: "string",
            },
            {
              content: (
                <>
                  <SignatoriesTable />
                </>
              ),
              title: (
                <>
                  <span className="title-with-icon">
                    <Icon name="DocumentIcon" />
                  </span>
                  Offer of Finance
                </>
              ),
              actionBtn: (
                <Box
                  theme={theme}
                  display={"flex"}
                  alignItems={"center"}
                  gap={2}
                >
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View/Edit",
                        optionkey: "view-edit",
                        optionValue: "0",
                      },
                      {
                        optionText: "Delete",
                        optionkey: "delete",
                        optionValue: "1",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </Box>
              ),
              key: "string-1",
              isExpanded: false,
              id: "string-1",
              ariaControls: "string-1",
            },
            {
              content: (
                <>
                  <SignatoriesTable />
                </>
              ),
              title: (
                <>
                  <span className="title-with-icon">
                    <Icon name="DocumentIcon" />
                  </span>
                  Suitability Letter
                </>
              ),
              actionBtn: (
                <Box
                  theme={theme}
                  display={"flex"}
                  alignItems={"center"}
                  gap={2}
                >
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View/Edit",
                        optionkey: "view-edit",
                        optionValue: "0",
                      },
                      {
                        optionText: "Delete",
                        optionkey: "delete",
                        optionValue: "1",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </Box>
              ),
              key: "string-2",
              isExpanded: false,
              id: "string-2",
              ariaControls: "string-2",
            },
          ]}
          shouldCollapse
        />
      </Box>
    </AddSignatoriesListWrap>
  );
};

export default SignatoriesList;
