import { Button, Icon, Menu } from "@ntpkunity/controls";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { AssetDetailsRowProps } from "./asset-details-row.props";
import { TableRowOptions } from "@helpers/enum";
import { useProposalFieldArrays } from "@contexts/proposal-form";
import { pounds } from "@helpers/utils";
import { useAssetSetups } from "@hooks/proposal";
import { useSumOfAssetCost } from "@hooks/proposal";
import {
  QuotationForm,
  useQuotationFormContext,
  useConfigurationActionContext,
} from "@ntpkunity/controls-common";

const AssetTableRow = ({
  index,
  asset,
  setEditAssetId,
  loadedFromSummary,
}: AssetDetailsRowProps) => {
  const { resetAssetCost } = useConfigurationActionContext();
  const { setValue: setValueQuotationForm } =
    useQuotationFormContext<QuotationForm>();
  const sumOfAssetCost = useSumOfAssetCost();
  const theme = useTheme();
  const {
    assetMethods: { remove },
  } = useProposalFieldArrays();

  const { assetSubTypes } = useAssetSetups();

  const subTypeName = asset.subType
    ? Object.values(assetSubTypes)
        .reduce((prev, cur) => [...prev, ...cur], [])
        .find((subType) => subType.code === asset.subType).name
    : "";

  const handleOptionClick = (_, key, value) => {
    if (key === TableRowOptions.VIEW_EDIT) {
      setEditAssetId(value);
      return;
    }
    if (key === TableRowOptions.DELETE) {
      remove(value);
      resetAssetCost(sumOfAssetCost - asset.totalCost);
      setValueQuotationForm("assetCost", sumOfAssetCost - asset.totalCost);
      return;
    }
  };

  return (
    <TableRow className="child-tr">
      <TableCell>{subTypeName}</TableCell>
      {!loadedFromSummary && <TableCell>{asset.description}</TableCell>}
      <TableCell>{asset.supplierName}</TableCell>
      <TableCell>{asset.quantity}</TableCell>
      <TableCell className="text-right">
        {pounds.format(asset.totalCost)}
      </TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={[
            {
              optionText: "View/Edit",
              optionkey: TableRowOptions.VIEW_EDIT,
              optionValue: asset.id,
            },
            {
              optionText: "Delete",
              optionkey: TableRowOptions.DELETE,
              optionValue: index,
            },
          ]}
          handleOptionClick={handleOptionClick}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default AssetTableRow;
