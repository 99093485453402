import { StatusConfirmationData } from "@_types/proposal";
import { ConfirmationDialog } from "@components";
import { OrderStatus } from "@helpers/enum";
import { Button, ISnackbarProps } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { useChangeProposalStatus } from "@hooks/mutations";
import { useState } from "react";
import { PROPOSAL_STATUS_TO_ALLOWED_STATUS_MAP } from "@helpers/const";
import { getUserSettings } from "@helpers/utils";

type StatusConfirmationDialogProps = {
  dialogData: StatusConfirmationData;
  closeDialog: () => void;
  open: boolean;
  closeStatusDialog: () => void;
};

export const StatusConfirmationDialog = ({
  dialogData,
  open,
  closeDialog,
  closeStatusDialog,
}: StatusConfirmationDialogProps) => {
  if (!open) return <></>;

  const [snackbarState, setSnackbarState] = useState<{
    open: boolean;
    message: string;
    variant?: ISnackbarProps["variant"];
  }>({
    open: false,
    message: "",
    variant: null,
  });

  const { mutateAsync: changeProposalStatus } = useChangeProposalStatus();
  const allowedStatuses: OrderStatus[] =
    PROPOSAL_STATUS_TO_ALLOWED_STATUS_MAP[dialogData.status];

  const handleWithdrawProposal = async () => {
    if (!allowedStatuses.includes(dialogData.nextStatus)) {
      setSnackbarState({
        message: `Transition from ${dialogData.status} to ${dialogData.nextStatus} is not allowed.`,
        open: true,
        variant: "error",
      });
      return;
    }
    try {
      setSnackbarState({
        message: "Changing Status...",
        open: true,
        variant: null,
      });
      await changeProposalStatus({
        identifier: dialogData.identifier,
        current_status: dialogData.status,
        next_status: dialogData.nextStatus,
        created_by: getUserSettings()?.user_name || null,
        comment_data: dialogData.proposalComment,
      });
      setSnackbarState({
        message: "Status Changed Successfully",
        open: true,
        variant: null,
      });
      closeDialog();
      closeStatusDialog();
    } catch (err) {
      setSnackbarState({
        message: "Error occurred while changing status",
        open: true,
        variant: "error",
      });
    }
  };

  const theme = useTheme();
  return (
    <>
      <ConfirmationDialog
        open={open}
        dialogIcon="DialogAlertIcon"
        title="Do you want to change proposal status?"
        message={
          <>
            Are you sure you want to change the status of {dialogData.name} (
            {dialogData.identifier}) from {dialogData.status} to{" "}
            {dialogData.nextStatus}
          </>
        }
        actionArea={
          <>
            <Button theme={theme} secondary text="No" onClick={closeDialog} />
            <Button
              theme={theme}
              danger
              text="Yes"
              onClick={handleWithdrawProposal}
            />
          </>
        }
        snackbarState={snackbarState}
        resetSnackbar={() =>
          setSnackbarState({ message: "", open: false, variant: undefined })
        }
      />
    </>
  );
};
