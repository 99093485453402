import { TProposalForm } from "@_types";
import { useFormContext } from "react-hook-form";
import { getSelectValues, removeDeletedEntities } from "@helpers/utils";
import {
  TInputDef,
  TLenderNames,
} from "@components/proposal/proposal-details/types";
import { PROPOSAL_DETAILS } from "@helpers/const";
import { useMemo } from "react";
import { useProposalDetailsSetups } from "@hooks/proposal";

type DealerOption = {
  label: string;
};

const useProposalDetailsDefs = () => {
  const { setValue, watch } = useFormContext<TProposalForm>();
  const productType = watch("proposalDetails.productType");
  const financeType = watch("proposalDetails.financeType");

  const { productTypes, financeTypes } = useProposalDetailsSetups();

  const getInputDefs = (): TInputDef[] => {
    return [
      {
        name: "proposalDetails.name",
        formElement: "Input",
        placeHolder: "Proposal Name",
        label: "Proposal Name",
        type: "text",
        sanitizeChange: (value) => {
          return value.replace(/[^a-zA-Z0-9 ]/g, "");
        },
      },
      {
        name: "proposalDetails.dealerName",
        formElement: "AutoComplete",
        label: "Dealer Name (Optional)",
        items: PROPOSAL_DETAILS.dealerNames.map((dealer: any) => {
          return { label: dealer };
        }),
        getOptionLabel: (option: DealerOption) => option.label,
        onChange: (_, value: any) => {
          setValue("proposalDetails.dealerName", value?.label);
        },
        renderOption: (props, option: DealerOption) => (
          <li {...props} key={option.label}>
            <span>{option.label}</span>
          </li>
        ),
      },
      {
        name: "proposalDetails.lenderName",
        formElement: "Select",
        label: "Lender Name",
        items: getSelectValues<TLenderNames>(PROPOSAL_DETAILS.lenderNames),
      },
      {
        name: "proposalDetails.financeType",
        formElement: "Select",
        label: "Finance Type",
        items: removeDeletedEntities(financeType, financeTypes)?.map(
          (financeType) => ({
            text: financeType.name,
            value: financeType.code,
          })
        ),
      },
      {
        name: "proposalDetails.productType",
        formElement: "Select",
        label: "Product Type",
        items: removeDeletedEntities(productType, productTypes)?.map(
          (productType) => ({
            text: productType.name,
            value: productType.code,
          })
        ),
      },
    ];
  };

  return useMemo(getInputDefs, [setValue, productTypes, financeTypes]);
};

export default useProposalDetailsDefs;
