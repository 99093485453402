import "react";
import {
  AddressLayout,
  Box,
  Button,
  Dialog,
  Grid,
  Input,
  Select,
} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { DialogContentContainer } from "./add-signatories.style";
import { GB_COUNTRY } from "@helpers/const";

const AddSignatoriesDialog = ({ open, onClose }) => {
  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      size={"sm"}
      onCloseDialog={onClose}
      open={open}
      title="Add Signatory Details"
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Cancel"
            onClick={onClose}
          />
          <Button
            theme={theme}
            type="button"
            primary
            text="Save Signatory"
            onClick={() => {}}
          />
        </>
      }
    >
      <DialogContentContainer
        theme={theme}
        className="dialog-content-container"
      >
        <Grid theme={theme} container item columnSpacing={2} rowSpacing={3}>
          <Grid theme={theme} item xs={12} md={6}>
            <Input
              theme={theme}
              type="text"
              label="First Name"
              fullWidth
              placeholder="Type here..."
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={6}>
            <Input
              theme={theme}
              type="text"
              label="Last Name"
              fullWidth
              placeholder="Type here..."
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Input
              theme={theme}
              type="text"
              label="Email"
              fullWidth
              placeholder="Type here..."
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Input
              theme={theme}
              type="text"
              label="Mobile Phone Number"
              fullWidth
              placeholder="Type here..."
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Select
              theme={theme}
              label="Position"
              fullWidth
              items={[
                {
                  text: "Option 1",
                  value: "0",
                },
                {
                  text: "Option 2",
                  value: "1",
                },
              ]}
            />
          </Grid>
        </Grid>
        <Box theme={theme} className="address-form">
          <AddressLayout
            theme={theme}
            state={{
              data: [],
              selectedItemObject: {
                text: "",
                value: "",
              },
              controlName: "",
            }}
            hideAddressButton={true}
            isContactRequired={false}
            addressVerificationRequired={false}
            countries={[GB_COUNTRY]}
            value={undefined}
            validationProps={undefined}
            onChange={() => {}}
          />
        </Box>
      </DialogContentContainer>
    </Dialog>
  );
};

export default AddSignatoriesDialog;
