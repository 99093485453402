import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AddSignatoriesWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-signatories-wrap": {
    ".action-area": {
      borderTop: "1px solid" + theme.palette.divider,
      paddingTop: 16,
    },
  },
}));

export const AddSignatoriesListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-signatories-list-wrap": {
    ".collapse-wrap": {
      "&.list-collapse": {
        [theme.breakpoints.down("md")]: {
          padding: 0,
        },
        ".u-accordion-header": {
          [theme.breakpoints.down("md")]: {
            padding: 0,
          },
        },
      },
    },
    ".u-accordion-header": {
      ".u-switch-label": {
        [theme.breakpoints.up("md")]: {
          ".MuiSwitch-root": {
            order: 2,
            "+": {
              ".MuiFormControlLabel-label": {
                order: 1,
                marginLeft: 0,
                marginRight: 8,
              },
            },
          },
        },
      },
    },
  },
}));

export const SignatoriesTableWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.signatories-table-wrap": {
    ".stack-wrap": {
      "&.bg-light": {
        [theme.breakpoints.down("md")]: {
          padding: 0,
          backgroundColor: "transparent",
        },
      },
    },
  },
}));

export const DialogContentContainer = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.dialog-content-container": {
    ".address-form": {
      borderTop: "1px solid" + theme.palette.divider,
      marginTop: 32,
      paddingTop: 8,
    },
  },
}));
