import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu } from "@ntpkunity/controls";
import { QuotationTableRowProps } from "./quotation-table-row.props";
import {
  getQuotationDownloadPayload,
  getTemplateName,
  pounds,
} from "@helpers/utils";
import dayjs from "dayjs";
import { TableRowOptions } from "@helpers/enum";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { useDownloadDocs } from "@hooks/mutations";
import { getQuotationByIdentifier } from "@services/dms.service";
import { mapQuotationData, useGetProductTypes } from "@hooks/queries";
import { useSnackbarContext } from "@contexts/snackbar";
import { DownloadTemplateTypes } from "@helpers/enum";
import { ProductTypeCell } from "@components/work-queue";

const QuotationTableRow = ({
  quotation,
  convertToProposal,
}: QuotationTableRowProps) => {
  const theme = useTheme();
  const updatedAt = dayjs(quotation.updated_at, "YYYY-MM-DD");
  const navigate = useNavigate();
  const { mutateAsync: downloadDocument } = useDownloadDocs();
  const { setSnackbar } = useSnackbarContext();
  const {
    isLoading: productTypesLoading,
    data: productTypes,
    isError: isErrorInProductTypes,
  } = useGetProductTypes();

  const handleDownloadQuotation = async (identifier: string) => {
    try {
      const quotationRawData = await getQuotationByIdentifier(identifier);
      const quotationSelectedData = mapQuotationData(quotationRawData);
      const quotationDownloadPayload = getQuotationDownloadPayload(
        {
          ...quotationSelectedData.formValues,
          ...quotationSelectedData.brokerSpecificFormValues,
        },
        quotationSelectedData.calculations
      );

      const productTypeName = productTypes.find(
        (pt) => pt.code === quotationSelectedData.formValues.productType
      )?.name;

      await downloadDocument({
        data_placeholders: quotationDownloadPayload,
        template_type: DownloadTemplateTypes.QUOTATION,
        template_name: getTemplateName(
          DownloadTemplateTypes.QUOTATION,
          productTypeName
        ),
        generate_base64: false,
        is_bookmark: false,
      });
    } catch (err) {
      setSnackbar(
        {
          open: true,
          message:
            err.response?.data?.detail ||
            "There was an error while downloading quotation",
          variant: "error",
        },
        5000
      );
    }
  };

  const handleOptionClick = async (_, key: TableRowOptions, value: string) => {
    switch (key) {
      case TableRowOptions.CONVERT_TO_PROPOSAL:
        convertToProposal(value);
        break;
      case TableRowOptions.VIEW_EDIT:
        navigate(`${APP_ROUTES.QUOTATION}/${value}`);
        break;
      case TableRowOptions.DOWNLOAD:
        await handleDownloadQuotation(value);
        break;
    }
  };

  return (
    <TableRow className="child-tr">
      <TableCell>{quotation.name}</TableCell>
      <TableCell>
        <ProductTypeCell productTypeCode={quotation.product_type} />
      </TableCell>
      <TableCell className="text-right">
        {pounds.format(quotation.finance_amount)}
      </TableCell>
      <TableCell>{updatedAt.format("DD/MM/YYYY")}</TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={[
            {
              optionText: "View/Edit",
              optionkey: TableRowOptions.VIEW_EDIT,
              optionValue: quotation.identifier,
            },
            {
              optionText: "Delete",
              disabled: true,
            },
            {
              optionText: "Convert to proposal",
              optionkey: TableRowOptions.CONVERT_TO_PROPOSAL,
              optionValue: quotation.identifier,
            },
            {
              optionText: "Download quotation",
              optionkey: TableRowOptions.DOWNLOAD,
              optionValue: quotation.identifier,
              disabled: productTypesLoading || isErrorInProductTypes,
            },
          ]}
          handleOptionClick={handleOptionClick}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default QuotationTableRow;
