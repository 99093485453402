export enum AuthenticationTypes {
  UNITY = "unity",
}

export enum ClientTypes {
  LIMITED_COMPANY = "Limited company",
  INDIVIDUAL = "Individual",
  PARTNERSHIP = "Partnership",
  SOLE_TRADER = "Sole Trader",
  GOV_ORG = "Government Organization",
  NON_PROFIT_ORG = "Not-for-profit Organization",
  SME = "SME",
}

export enum FinanceRegulationType {
  REGULATED = "Regulated",
  UNREGULATED = "Unregulated",
}

export enum AssetTypes {
  VEHICLE = "Vehicle",
  EQUIPMENT = "Equipment",
  SOFTWARE = "Software",
}

export enum HardAssetConditions {
  NEW = "New",
  USED = "Used",
}

export enum AssetCategories {
  SOFT_ASSET = "Soft Asset",
  HARD_ASSET = "Hard Asset",
}

export enum TableRowOptions {
  VIEW_EDIT = "view-edit",
  DELETE = "delete",
  VIEW = "view",
  UPLOAD = "upload",
  EDIT = "edit",
  CONVERT_TO_PROPOSAL = "convert-to-proposal",
  DOWNLOAD = "download",
  CLONE = "clone",
  WITHDRAW = "withdraw",
  OPEN_DIARY = "open-diary",
  PROPOSAL_SUMMARY = "proposal-summary",
  GENERATE_DOCUMENTS = "generate-documents",
  CHECK_SIGNATORY_STATUS = "signatory-status",
  VIEW_CONDITIONS = "view-conditions",
  SEND_FOR_PAYOUT = "send-for-payout",
  MANUAL_STATUS_CHANGE = "manual-status-change",
  VIEW_SIGNATORIES = "view-signatories",
}

export enum ProposalProductTypes {
  HIRE_PURCHASE = "Hire Purchase - HP",
  PERSONAL_CONTACT_PURCHASE = "Personal Contact Purchase - PCP",
  FINANCE_LEASE = "Finance Lease - FL",
  LOAN = "Loan",
  ASSET_BASED_LOAN = "Asset Based Loan",
}

export enum ProposalTabs {
  PROPOSAL_DETAILS = "Proposal Details",
  ASSET_DETAILS = "Asset Details",
  FINANCE_DETAILS = "Finance Details",
  CREDIT_DOCUMENTS = "Credit Documents",
  SUMMARY = "Summary",
}

export enum OrderStage {
  APPLICATION = "Application",
  QUOTATION = "Quotation",
}

export enum WorkQueueTabs {
  QUOTATIONS = "quotations",
  DRAFT_PROPOSALS = "draft-proposals",
  TRACK_PROPOSALS = "track-proposals",
  DEALER_PROPOSALS = "dealer-proposals",
  DASHBOARD_PROPOSALS = "dashboard-proposals",
}

export enum OrderStatus {
  DRAFT = "Draft",
  WITHDRAWN = "Withdrawn",
  SUBMITTED = "Submitted",
  SUBMITTED_TO_BROKER = "Submitted to Broker",
  CONDITIONED = "Conditioned",
  DECLINED = "Declined",
  DOCUMENT_SENT = "Documents Sent",
  DOCUMENT_RECEIVED = "Documents Received",
  SENT_FOR_PAYOUT = "Sent for Payout",
  PAID_OUT = "Paid Out",
  ACCEPTED = "Approved",
  AWAITING_COMPLIANCE = "Awaiting Compliance",
  COMPLIED = "Complied",
}

export enum OrderStatusCategory {
  ACTIVE = "Active",
  CLOSED = "Closed",
}

export enum QuoteDataPlaceholderOptions {
  CONTEXT = "Context",
  OBJECT = "Object",
}

export enum AmountType {
  POUND = "£",
  PERCENTAGE = "%",
}

export enum ProposalStakeholders {
  DEALER = "Dealer",
  LENDER = "Lender",
}

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum SnackbarVariants {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
}

export enum ComparisonViewMode {
  INPUT = "input",
  RESULT = "result",
}

export enum DashboardRangeFilter {
  WEEK = "Week",
  MONTH = "Month",
  QUARTER = "Quarter",
  CALENDER_YEAR = "CalenderYear",
}

export enum PromiseStates {
  REJECTED = "rejected",
}

export enum ApplicationMode {
  DEALER = "dealer",
}

export enum DocumentTypes {
  CREDIT_DOCUMENTS = "Credit Documents",
}

export enum ProposalAgeCategories {
  LESS_THAN_TWO = "<2 Days",
  THREE_TO_FIVE = "3 - 5",
  SIX_TO_TEN = "6 - 10",
  MORE_THAN_TEN = ">10",
}
export enum AppDialogKeys {
  MANUAL_STATUS_CHANGE = "manual-status-change",
  VIEW_CONDITIONS = "view-conditions",
  WITHDRAW_CONFIRMATION = "withdraw-confirmation",
  COMPANY_LOOKUP = "company-lookup",
  ADDRESS_LOOKUP = "address-lookup",
}

export enum CompaniesLookupTabs {
  BY_NAME = "Company Name",
  BY_REG_NUM = "Registration Number",
}

export enum ActionOptions {
  LOGOUT = "Logout",
}

export enum CommentCategory {
  GENERAL = "General",
  UNDERWRITING = "Underwriting",
  CHANGE_REQUESTS = "Change Requests",
}

export enum SessionStorageKeys {
  QUOTATION_INPUTS = "QUOTATION_INPUTS",
}

export enum DownloadTemplateTypes {
  QUOTATION = "QUOTATION",
  PROPOSAL_INDIVIDUAL = "PROPOSAL_INDIVIDUAL",
  PROPOSAL_COMPANY = "PROPOSAL_COMPANY",
}
