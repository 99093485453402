import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getFinanceTypes } from "@services/index.service";
import { FinanceTypeSetupRes } from "@_types/setups";

const useGetFinanceTypes = (companyId: number) => {
  return useQuery<number, unknown, FinanceTypeSetupRes>({
    queryKey: [QUERY_KEYS.GET_FINANCE_TYPES, companyId],
    queryFn: () => getFinanceTypes(companyId),
    enabled: !!companyId,
  });
};

export default useGetFinanceTypes;
