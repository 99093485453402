import "react";
import { PageHeader } from "@components/page-header";
import { Button } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { useGetSaveProposalPayload } from "@hooks/proposal";
import { useSaveProposal, useUpdateProposal } from "@hooks/mutations";
import { useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { useSnackbarContext } from "@contexts/snackbar";
import { OrderStatus, WorkQueueTabs, SnackbarVariants } from "@helpers/enum";
import { PageHeaderProps } from "./page-header.props";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types/forms";
import { DiaryDialogData } from "@_types";
import { DiaryDialog } from "@components/diary/diary-dialog/diary-dialog";
import { useDialogState } from "@hooks";
import { useQueryClient } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";

const ProposalPageHeader = ({
  showActionBtn = true,
  isLastStep,
}: PageHeaderProps) => {
  const queryClient = useQueryClient();
  const params = useParams<{ proposalId?: string }>();
  const isEditMode = !!params.proposalId;
  const navigate = useNavigate();
  const theme = useTheme();
  const { mutateAsync: saveProposal } = useSaveProposal();
  const { mutateAsync: updateProposal } = useUpdateProposal();
  const { watch } = useFormContext<TProposalForm>();
  const { setSnackbar } = useSnackbarContext();
  const { getPayload, validateProposalPayload } = useGetSaveProposalPayload(
    params.proposalId
  );
  const currentStatus = watch("status");

  const mutateProposal = isEditMode ? updateProposal : saveProposal;

  const handleSaveProposal = async () => {
    const payload = getPayload(currentStatus);
    if (validateProposalPayload(payload)) {
      try {
        setSnackbar({ open: true, message: "Saving Proposal" });
        await mutateProposal(payload);

        if (isEditMode) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_DIARY_COMMENTS, params.proposalId],
          });
        }

        navigate(
          `${APP_ROUTES.WORKQUEUE}/${
            currentStatus === OrderStatus.DRAFT
              ? WorkQueueTabs.DRAFT_PROPOSALS
              : WorkQueueTabs.TRACK_PROPOSALS
          }`
        );
      } catch (err) {
        setSnackbar({
          open: true,
          message: "Error Occurred while saving Proposal",
          variant: SnackbarVariants.ERROR,
        });
      }
    }
  };

  const {
    dialogOpen: diaryDialogOpened,
    dialogData: diaryDialogData,
    openDialog: openDiaryDialog,
    closeDialog: closeDiaryDialog,
  } = useDialogState<DiaryDialogData>();

  const handleOpenDiaryDialog = () => {
    openDiaryDialog({ proposalId: params.proposalId });
  };
  return (
    <PageHeader
      title={false}
      hasInput
      inputPlaceholder="Enter Proposal Name..."
      inputName="proposalDetails.name"
      actionArea={
        showActionBtn && (
          <>
            <Button
              theme={theme}
              type="button"
              text="Save Proposal"
              secondary
              onClick={handleSaveProposal}
            />
            <Button
              theme={theme}
              type="button"
              text="Diary"
              secondary
              disabled={!params.proposalId}
              onClick={handleOpenDiaryDialog}
            />
            {isLastStep && (
              <Button
                theme={theme}
                type="button"
                text="Send To Customer"
                primary
                onClick={() =>
                  window.open(APP_ROUTES.CUSTOMER_PROPOSAL, "_blank")
                }
              />
            )}
            <DiaryDialog
              dialogData={diaryDialogData}
              open={diaryDialogOpened}
              closeDialog={closeDiaryDialog}
            />
          </>
        )
      }
    />
  );
};

export default ProposalPageHeader;
