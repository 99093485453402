import { dmsAPI } from "./instances";
import {
  GET_QUOTATION_BY_IDENTIFIER,
  GET_ORDER_WORKQUEUE,
  GET_ORDER_STATUS_HISTORY,
  CONVERT_QUOTATION_TO_PROPOSAL,
  UPDATE_QUOTATION,
  GET_PROPOSAL_BY_REFERENCE_NUMBER,
  SAVE_UPDATE_PROPOSAL,
  DASHBOARD_ANALYTICS,
  CHANGE_PROPOSAL_STATUS,
  SAVE_DIARY_COMMENT,
  GET_DIARY_COMMENTS,
} from "@helpers/endpoints";
import { getQueryParamsString } from "@helpers/utils";
import {
  ChangeProposalStatusReq,
  GetQuotationResponse,
  TProposalPayloadReq,
} from "@_types";
import { TOrderStatusHistoryReq } from "@_types/auditTrail";
import { TCommentsDiaryDetails } from "@_types/forms/diaryComments";

const baseUrl = `${process.env.REACT_APP_DMS_URL}`;

export const getQuotationByIdentifier = async (
  identifier: string
): Promise<GetQuotationResponse> => {
  return dmsAPI
    .get(GET_QUOTATION_BY_IDENTIFIER.replace(":identifier", identifier))
    .then((response) => {
      const data = response.data;
      return data;
    });
};

export const getOrderWorkqueue = async (
  searchParams: { [key: string]: any },
  sortParams: { [key: string]: any }
) => {
  const queryParamsStr = getQueryParamsString({
    ...searchParams,
    ...sortParams,
  });

  let apiEndpoint = GET_ORDER_WORKQUEUE;
  if (!!queryParamsStr) {
    apiEndpoint += queryParamsStr;
  }
  return dmsAPI.get(apiEndpoint).then((response) => response.data);
};

export const getProposalByReferenceNumber = async (referenceNumber: string) => {
  return dmsAPI
    .get(
      GET_PROPOSAL_BY_REFERENCE_NUMBER.replace(
        ":referenceNumber",
        referenceNumber
      )
    )
    .then((response) => {
      const data = response.data;
      return data;
    });
};

export const getOrderStatusHistory = async (
  payload: TOrderStatusHistoryReq
) => {
  return dmsAPI
    .post(GET_ORDER_STATUS_HISTORY, payload)
    .then((response) => response.data);
};
export const convertQuotationToProposal = async (identifier: string) => {
  return dmsAPI.patch(
    CONVERT_QUOTATION_TO_PROPOSAL.replace(":identifier", identifier),
    { identifier: identifier }
  );
};

export const saveProposal = async (payload: TProposalPayloadReq) => {
  return dmsAPI
    .post(SAVE_UPDATE_PROPOSAL, payload)
    .then((response) => response.data);
};

export const updateProposal = async (payload: TProposalPayloadReq) => {
  return dmsAPI
    .patch(`${SAVE_UPDATE_PROPOSAL}?identifier=${payload.identifier}`, payload)
    .then((response) => response.data);
};

export const saveQuotation = async (payload: any) => {
  return dmsAPI.post(`${baseUrl}${"order/quick_quotation"}`, payload);
};

export const updateQuotationTwo = async (payload: any) => {
  const queryParamsStr = getQueryParamsString({
    identifier: payload.identifier,
  });

  return dmsAPI.patch(UPDATE_QUOTATION + queryParamsStr, payload);
};

export const getProposalForDashboard = async (
  searchParams: { [key: string]: any },
  sortParams: { [key: string]: any }
) => {
  const queryParamsStr = getQueryParamsString({
    ...searchParams,
    ...sortParams,
  });

  let apiEndpoint = DASHBOARD_ANALYTICS;
  if (!!queryParamsStr) {
    apiEndpoint += queryParamsStr;
  }
  return dmsAPI.get(apiEndpoint).then((response) => response.data);
};

export const changeProposalStatus = async (
  requestBody: ChangeProposalStatusReq
) => {
  return dmsAPI.patch(CHANGE_PROPOSAL_STATUS, requestBody);
};

export const saveDiaryComment = async (payload: TCommentsDiaryDetails) => {
  return dmsAPI
    .post(SAVE_DIARY_COMMENT, payload)
    .then((response) => response.data);
};

export const getDiaryComments = async (
  orderReferenceId: string
): Promise<TCommentsDiaryDetails[]> => {
  let endpointUrl = GET_DIARY_COMMENTS;
  const searchParams = { order_reference_id: orderReferenceId };
  const queryParamsStr = getQueryParamsString(searchParams);
  if (!!queryParamsStr) {
    endpointUrl += queryParamsStr;
  }

  return dmsAPI.get(endpointUrl).then((response) => {
    return response.data.result;
  });
};
