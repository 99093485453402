import { useTheme } from "@mui/material";
import { Button } from "@ntpkunity/controls";
import { FinancialConfigWrap } from "./financial-configurations.style";
import { FinancialConfigurationTabs } from "./config-tabs/config-tabs.component";
import { PageHeader, Stack } from "@components";
import { FC, useState } from "react";
import {
  ProductType,
  useConfigurationContext,
} from "@ntpkunity/controls-common";
import { useSaveFinancialConfigurations } from "@hooks/mutations/useSaveConfigurations";
import { FormProvider, useForm } from "react-hook-form";

export const FinancialConfigurations: FC<{}> = () => {
  const theme = useTheme();
  const [productType, setProductType] = useState(ProductType.HIRE_PURCHASE);
  const { data: configurations } = useConfigurationContext();
  const { mutate: saveFinancialConfigurations } =
    useSaveFinancialConfigurations();

  const form = useForm({
    shouldUnregister: false,
    defaultValues: configurations,
  });
  const onsubmit = (data) => {
    const updatedFees = data[productType]?.fees?.map((fee) => ({
      PaymentNumber: fee.paymentNumber ?? 0,
      FirstDueDate: new Date().toString(),
      Name: fee?.name,
      Frequency: fee?.type,
      Occurance: fee?.frequency,
      Amount: fee?.amount,
      IsEditable: fee?.isEditable,
      FirstFeePaymentDueAtStartDate: fee?.firstFeePaymentDueAtStartDate,
    }));
    const updatedFields = data[productType]?.fieldProps?.map((field) => ({
      Name: field?.name,
      Label: field?.label,
      Hidden: field?.hidden,
      Options: field?.options,
      Disabled: field?.disabled,
      ControlType: field?.controlType,
      DefaultValue: field?.defaultValue,
    }));
    const updatedData = {
      AmortizationMethod: data[productType]?.amortizationMethod,
      APRCalculationMethod: data[productType]?.aprCalculationMethod,
      InitialCashOutFlowForFlatRate:
        data[productType]?.initialCashOutFlowForFlatRate,
      CommissionCalculationMethod:
        data[productType]?.commissionCalculationMethod,
      IsCommissionPassToCustomer: data[productType]?.isCommissionPassToCustomer,
      IsVATApplicable: data[productType]?.isVATApplicable,
      Fees: updatedFees,
      Tax: 0,
      TaxType: data[productType]?.taxType,
      GrossProfit: data[productType]?.grossProfit,
      FieldProps: updatedFields,
    };
    saveFinancialConfigurations({ ...updatedData, productType }, {});
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onsubmit)}>
        <FinancialConfigWrap theme={theme} className="financial-config-wrap">
          <PageHeader
            title={"Financial Configurations"}
            actionArea={
              <>
                <Button
                  theme={theme}
                  primary
                  type="submit"
                  text={"Save Changes"}
                  onClick={() => {}}
                />
              </>
            }
          />
          <Stack paddingMd={3} paddingXs={3}>
            <FinancialConfigurationTabs
              productType={productType}
              setProductType={setProductType}
            />
          </Stack>
        </FinancialConfigWrap>
      </form>
    </FormProvider>
  );
};
