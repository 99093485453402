import { QuotationTool } from "@components/new-quotation";
import {
  ProductType,
  QuotationForm,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";

const FinanceDetails = () => {
  const { setValue } = useFormContext<TProposalForm>();
  const { watch } = useQuotationFormContext<QuotationForm>();
  const productType = watch("productType");

  useEffect(() => {
    setValue("proposalDetails.productType", productType as ProductType);
  }, [productType]);

  return <QuotationTool />;
};

export default FinanceDetails;
