import { useEffect, useState } from "react";
import { ClientTypes } from "@helpers/enum";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";
import useClientTypesSetup from "./useClientTypesSetups";

const PARTNER_SPECIFIC_NAMES = [
  "companyName",
  "companyRegNum",
  "tradingAs",
  "natureOfBusiness",
  "yearsInBusiness",
];

const FIELD_NAMES = {
  [ClientTypes.INDIVIDUAL]: [
    "firstName",
    "lastName",
    "idNumber",
    "emailAddress",
    "contactNumber",
  ],
  [ClientTypes.PARTNERSHIP]: PARTNER_SPECIFIC_NAMES,
  [ClientTypes.LIMITED_COMPANY]: [...PARTNER_SPECIFIC_NAMES, "sicCode"],
  [ClientTypes.GOV_ORG]: PARTNER_SPECIFIC_NAMES,
  [ClientTypes.NON_PROFIT_ORG]: PARTNER_SPECIFIC_NAMES,
  [ClientTypes.SOLE_TRADER]: PARTNER_SPECIFIC_NAMES,
  [ClientTypes.SME]: PARTNER_SPECIFIC_NAMES,
};

const useCustomerFields = () => {
  const [customerFields, setCustomerFields] = useState<string[]>([
    "customerDetails.clientType",
  ]);
  const clientTypes = useClientTypesSetup();

  const { watch } = useFormContext<TProposalForm>();
  const clientType = watch("customerDetails.clientType");

  const updateCustomerFields = (clientType: string) => {
    const clientTypeName = clientTypes.find((ct) => ct.code === clientType)
      ?.description as ClientTypes;

    const isValidClientType = () =>
      Object.values(ClientTypes).includes(clientTypeName);

    if (!clientType || !isValidClientType()) {
      return setCustomerFields(["customerDetails.clientType"]);
    }

    setCustomerFields(
      ["clientType", ...FIELD_NAMES[clientTypeName]]
        .filter(Boolean)
        .map((fieldName) => `customerDetails.${fieldName}`)
    );
  };

  useEffect(() => {
    updateCustomerFields(clientType);
  }, [clientType]);

  return customerFields;
};

export default useCustomerFields;
