import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getAssetConditions } from "@services/index.service";
import { AssetConditionsSetupRes } from "@_types/setups";

const useGetAssetConditions = (companyId: number) => {
  return useQuery<number, unknown, AssetConditionsSetupRes>({
    queryKey: [QUERY_KEYS.GET_ASSET_CONDITIONS, companyId],
    queryFn: () => getAssetConditions(companyId),
    enabled: !!companyId,
  });
};

export default useGetAssetConditions;
