import {
  WorkQueueTabs,
  OrderStatus,
  ProposalTabs,
  ClientTypes,
  DashboardRangeFilter,
  OrderStatusCategory,
  DocumentTypes,
  ProposalAgeCategories,
  TableRowOptions,
} from "@helpers/enum";
import {
  DashboardForm,
  TCompanyDetails,
  TIndividualDetails,
  TProposalForm,
} from "@_types";
import { DefaultValues } from "react-hook-form";

export const LOCAL_STORAGE_KEYS = {
  MODE: "mode",
  SETTINGS: "settings",
};

export const DEFAULT_PAGE_NUMBER = "0";
export const DEFAULT_PAGE_SIZE = 10;

const BROKER_BASE_ROUTE = "/broker-app";
const AUTHENTICATION_ROUTE = "/authentication";

export const APP_ROUTES = {
  BASE_URL: BROKER_BASE_ROUTE,
  UNKNOW_URL: BROKER_BASE_ROUTE + "/*",
  DASHBOARD: BROKER_BASE_ROUTE + "/dashboard",
  QUOTATION: BROKER_BASE_ROUTE + "/quotation",
  NEW_QUOTATION: BROKER_BASE_ROUTE + "/new-quotation",
  PROPOSAL: BROKER_BASE_ROUTE + "/proposal",
  WORKQUEUE: BROKER_BASE_ROUTE + "/work-queue",
  CONFIGURATIONS: BROKER_BASE_ROUTE + "/configurations",
  SETUPS: BROKER_BASE_ROUTE + "/setups",
  ANALYTICS: BROKER_BASE_ROUTE + "/analytics",
  DIARY: BROKER_BASE_ROUTE + "/diary",
  QUOTATION_COMPARISON: BROKER_BASE_ROUTE + "/quotation-comparison",
  DOCUMENT: BROKER_BASE_ROUTE + "/document",
  SEND_FOR_PAYOUT: BROKER_BASE_ROUTE + "/send-for-payout",
  SIGN_IN: BROKER_BASE_ROUTE + AUTHENTICATION_ROUTE + "/sign-in",
  DEALER_PROPOSAL: BROKER_BASE_ROUTE + "/work-queue/dealer-proposal",
  CUSTOMER_PROPOSAL: BROKER_BASE_ROUTE + "/customer-proposal",
  SIGNATORY_STATUS: BROKER_BASE_ROUTE + "/signatory-status",
  LENDER_DASHBOARD: BROKER_BASE_ROUTE + "/lender-dashboard",
  FINANCIAL_CONFIGURATIONS: BROKER_BASE_ROUTE + "/financial-configurations",
  COMPLIANCE_DOCUMENT: BROKER_BASE_ROUTE + "/compliance-document",
  USER_MANAGEMENT: BROKER_BASE_ROUTE + "/user-management",
};

/***** Hard Coded for Demo Purposes *****/
export const ASSET_INFO = [
  "Hard Asset - Vehicle - New vehicle",
  "Hard Asset - Vehicle - Used vehicle",
  "Hard Asset - Vehicle - Classic Cars",
  "Hard Asset - Equipment - Agricultural Equipment",
  "Hard Asset - Equipment - Office Equipment",
  "Hard Asset - Equipment - Construction Equipment",
] as const;

export const CUSTOMER_TYPES = [...Object.values(ClientTypes)] as const;

export const CUSTOMER_TYPES_DISABLED = {
  [ClientTypes.GOV_ORG]: true,
  [ClientTypes.NON_PROFIT_ORG]: true,
  [ClientTypes.SOLE_TRADER]: true,
};

export const BROKER_NAMES = ["Unity Broker", "Appexnow Broker"] as const;

export const PROPOSAL_DETAILS = {
  dealerNames: [
    "Classic Cars Dealer",
    "Machinery Traders",
    "Molson Equipment Dealer",
    "North Truck Dealers",
  ],
  lenderNames: [
    "United Trust Bank",
    "Close Brothers Bank",
    "Haydock Finance",
    "Lloyds Bank",
  ],
  brokerNames: ["Unity Broker", "Appexnow Broker"],
  financeTypes: ["Regulated", "Unregulated"],
} as const;

const COMPANY_DOCUMENT_TYPES = [
  "Financial Statements",
  "Directors Approval",
  "Company Registration Cerificate",
  "Business Proposal",
] as const;

export const DOCUMENT_TYPES = {
  [ClientTypes.INDIVIDUAL]: ["Id Document", "Proof of Address"],
  [ClientTypes.LIMITED_COMPANY]: COMPANY_DOCUMENT_TYPES,
  [ClientTypes.PARTNERSHIP]: COMPANY_DOCUMENT_TYPES,
  [ClientTypes.NON_PROFIT_ORG]: COMPANY_DOCUMENT_TYPES,
  [ClientTypes.SOLE_TRADER]: COMPANY_DOCUMENT_TYPES,
  [ClientTypes.GOV_ORG]: COMPANY_DOCUMENT_TYPES,
} as const;

export const ALLOWED_FILE_TYPES = {
  [DocumentTypes.CREDIT_DOCUMENTS]: [
    "application/pdf",
    "image/jpeg",
    "image/png",
  ],
} as const;

export const GB_COUNTRY = {
  is_active: true,
  is_deleted: false,
  id: 228,
  name: "United Kingdom",
  code: "44",
  country_code: "GB",
  address_template: {
    is_active: true,
    is_deleted: false,
    id: 4,
    country_id: 229,
    field1_label: "City",
    field1_visible: true,
    field2_label: null,
    field2_visible: null,
    field3_label: "Post Code",
    field3_visible: true,
    field4_label: "County",
    field4_visible: true,
    field5_label: null,
    field5_visible: null,
    field6_label: null,
    field6_visible: null,
  },
};
/****************************************/

export const INDIVIDUAL_LABEL_AND_KEYS: {
  label: string;
  key: keyof TIndividualDetails;
}[] = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "ID Number", key: "idNumber" },
  { label: "Email Address", key: "emailAddress" },
  { label: "Contact Number", key: "contactNumber" },
];

export const COMPANY_LABEL_AND_KEYS: {
  label: string;
  key: keyof TCompanyDetails;
}[] = [
  { label: "Company Name", key: "companyName" },
  { label: "Registration Number", key: "companyRegNum" },
  { label: "Trading As", key: "tradingAs" },
  { label: "Years in Business", key: "yearsInBusiness" },
];

export const CURRENCY_CODES = {
  GBP: "GBP",
};

export const LOCALES = {
  GB: "en-GB",
};

export const WORK_QUEUE_TABS = [
  {
    title: "Track Proposals",
    value: WorkQueueTabs.TRACK_PROPOSALS,
  },
  {
    title: "Dealer Proposal",
    value: WorkQueueTabs.DEALER_PROPOSALS,
  },
  {
    title: "Draft Proposals",
    value: WorkQueueTabs.DRAFT_PROPOSALS,
  },
  {
    title: "Quotations",
    value: WorkQueueTabs.QUOTATIONS,
  },
];

export const DEALER_WORK_QUEUE_TABS = [
  {
    title: "Track Proposals",
    value: WorkQueueTabs.TRACK_PROPOSALS,
  },
  {
    title: "Draft Proposals",
    value: WorkQueueTabs.DRAFT_PROPOSALS,
  },
  {
    title: "Quotations",
    value: WorkQueueTabs.QUOTATIONS,
  },
];

export const QUEUE_TO_STATUS_MAP = {
  [WorkQueueTabs.DRAFT_PROPOSALS]: [OrderStatus.DRAFT],
  [WorkQueueTabs.TRACK_PROPOSALS]: [
    OrderStatus.ACCEPTED,
    OrderStatus.SUBMITTED,
    OrderStatus.CONDITIONED,
    OrderStatus.DECLINED,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.PAID_OUT,
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.WITHDRAWN,
    OrderStatus.AWAITING_COMPLIANCE,
    OrderStatus.COMPLIED,
  ],
  [WorkQueueTabs.DASHBOARD_PROPOSALS]: [
    OrderStatus.ACCEPTED,
    OrderStatus.SUBMITTED,
    OrderStatus.CONDITIONED,
    OrderStatus.DECLINED,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.PAID_OUT,
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.AWAITING_COMPLIANCE,
    OrderStatus.COMPLIED,
  ],
  [WorkQueueTabs.DEALER_PROPOSALS]: [OrderStatus.SUBMITTED_TO_BROKER],
};

export const PROPOSAL_STATUSES_BY_CATEGORY = {
  [OrderStatusCategory.ACTIVE]: [
    OrderStatus.SUBMITTED,
    OrderStatus.ACCEPTED,
    OrderStatus.CONDITIONED,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.AWAITING_COMPLIANCE,
    OrderStatus.COMPLIED,
  ],
  [OrderStatusCategory.CLOSED]: [
    OrderStatus.DECLINED,
    OrderStatus.WITHDRAWN,
    OrderStatus.PAID_OUT,
  ],
};

export const PROPOSAL_AGE_CATEGORY = {
  [ProposalAgeCategories.LESS_THAN_TWO]: [0, 2],
  [ProposalAgeCategories.THREE_TO_FIVE]: [3, 5],
  [ProposalAgeCategories.SIX_TO_TEN]: [6, 10],
  [ProposalAgeCategories.MORE_THAN_TEN]: [10, 1000],
};

export const DRAFT_QUEUE_STATUSES = [
  {
    text: "All",
    value: "all",
  },
  ...QUEUE_TO_STATUS_MAP[WorkQueueTabs.DRAFT_PROPOSALS].map((status) => ({
    text: status,
    value: status,
  })),
];

export const TRACK_QUEUE_STATUSES = [
  {
    text: "All",
    value: "all",
  },
  ...QUEUE_TO_STATUS_MAP[WorkQueueTabs.TRACK_PROPOSALS].map((status) => ({
    text: status,
    value: status,
  })),
];

export const DEALER_QUEUE_STATUSES = [
  ...QUEUE_TO_STATUS_MAP[WorkQueueTabs.DEALER_PROPOSALS].map((status) => ({
    text: status,
    value: status,
  })),
];

export const DASHBOARD_QUEUE_STATUSES = [
  {
    text: "All",
    value: "all",
  },
  ...QUEUE_TO_STATUS_MAP[WorkQueueTabs.DASHBOARD_PROPOSALS].map((status) => ({
    text: status,
    value: status,
  })),
];

export const PROPOSAL_STEP_ITEMS_INITIAL_STATE = [
  { title: ProposalTabs.PROPOSAL_DETAILS, disabled: false },
  { title: ProposalTabs.ASSET_DETAILS, disabled: false },
  { title: ProposalTabs.FINANCE_DETAILS, disabled: false },
  { title: ProposalTabs.CREDIT_DOCUMENTS, disabled: false },
  { title: ProposalTabs.SUMMARY, disabled: false },
];

export const MAX_NUMBER_OF_QUOTATIONS = 3;
export const MIN_NUMBER_OF_QUOTATIONS = 2;
export const DASHBOARD_RANGE_FILTER_VALUES = [
  {
    text: DashboardRangeFilter.WEEK,
    value: DashboardRangeFilter.WEEK,
  },
  {
    text: DashboardRangeFilter.MONTH,
    value: DashboardRangeFilter.MONTH,
  },
  {
    text: DashboardRangeFilter.QUARTER,
    value: DashboardRangeFilter.QUARTER,
  },
  {
    text: "Calendar Year",
    value: DashboardRangeFilter.CALENDER_YEAR,
  },
];

export const DASHBOARD_FORM_DEFAULT_VALUES: DefaultValues<DashboardForm> = {
  rangeFilter: DashboardRangeFilter.MONTH,
  totalRangeFilter: DashboardRangeFilter.MONTH,
  agingRangeFilter: DashboardRangeFilter.MONTH,
};

export const PROPOSAL_STATUS_TO_ALLOWED_STATUS_MAP = {
  [OrderStatus.DRAFT]: [OrderStatus.SUBMITTED, OrderStatus.WITHDRAWN],
  [OrderStatus.SUBMITTED]: [
    OrderStatus.CONDITIONED,
    OrderStatus.ACCEPTED,
    OrderStatus.DECLINED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.CONDITIONED]: [
    OrderStatus.ACCEPTED,
    OrderStatus.SUBMITTED,
    OrderStatus.DECLINED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.ACCEPTED]: [
    OrderStatus.WITHDRAWN,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.AWAITING_COMPLIANCE,
  ],
  [OrderStatus.DECLINED]: [OrderStatus.SUBMITTED],
  [OrderStatus.WITHDRAWN]: [OrderStatus.SUBMITTED],
  [OrderStatus.DOCUMENT_SENT]: [
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.DOCUMENT_RECEIVED]: [
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.SENT_FOR_PAYOUT]: [OrderStatus.PAID_OUT],
  [OrderStatus.PAID_OUT]: [],
  [OrderStatus.AWAITING_COMPLIANCE]: [
    OrderStatus.COMPLIED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.COMPLIED]: [
    OrderStatus.WITHDRAWN,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.SENT_FOR_PAYOUT,
  ],
};

export const PROPOSAL_STATUS_TO_ROW_OPTION_MAP = {
  [OrderStatus.DRAFT]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.SUBMITTED]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.WITHDRAW,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.SUBMITTED_TO_BROKER]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
  ],
  [OrderStatus.CONDITIONED]: [
    TableRowOptions.VIEW_CONDITIONS,
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.WITHDRAW,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.ACCEPTED]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.WITHDRAW,
    TableRowOptions.GENERATE_DOCUMENTS,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.DECLINED]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.WITHDRAW,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.WITHDRAWN]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.OPEN_DIARY,
    TableRowOptions.MANUAL_STATUS_CHANGE,
  ],
  [OrderStatus.DOCUMENT_SENT]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.WITHDRAW,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.DOCUMENT_RECEIVED]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.SENT_FOR_PAYOUT]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.PAID_OUT]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.AWAITING_COMPLIANCE]: [
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.WITHDRAW,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.UPLOAD,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.COMPLIED]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.WITHDRAW,
    TableRowOptions.GENERATE_DOCUMENTS,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ],
};

export const PROPOSAL_OPTION_TEXT_MAP = {
  [TableRowOptions.VIEW_EDIT]: "View/Edit Proposal",
  [TableRowOptions.PROPOSAL_SUMMARY]: "View Proposal Summary",
  [TableRowOptions.UPLOAD]: "Upload Documents",
  [TableRowOptions.DOWNLOAD]: "Download Proposal",
  [TableRowOptions.CLONE]: "Clone Proposal",
  [TableRowOptions.WITHDRAW]: "Withdraw Proposal",
  [TableRowOptions.OPEN_DIARY]: "Diary",
  [TableRowOptions.VIEW_CONDITIONS]: "View Conditions",
  [TableRowOptions.GENERATE_DOCUMENTS]: "Generate Documents",
  [TableRowOptions.CHECK_SIGNATORY_STATUS]: "View Signatories",
  [TableRowOptions.SEND_FOR_PAYOUT]: "Send for Payout",
  [TableRowOptions.MANUAL_STATUS_CHANGE]: "Manually Change Status",
};

export const PAYOUT_STATUS = [
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Signed",
    color: "ind-success",
  },
];

export const PROPOSAL_SUMMARY_STATUS = [
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
];

export const DOCUMENT_SIGNATORY_STATUS = [
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
];

export const AMORTIZATION_METHODS = [
  { value: "Annuity30-360", text: "Annuity - 30/360" },
  { value: "AnnuityActual-360", text: "Annuity - Actual/360" },
  { value: "AnnuityActual-365", text: "Annuity - Actual/365" },
];
export const APR_CALCULATION_METHODS = [
  { value: "Annuity30-360", text: "Annuity - 30/360" },
  { value: "AnnuityActual-360", text: "Annuity - Actual/360" },
  { value: "AnnuityActual-365", text: "Annuity - Actual/365" },
];
export const INITIAL_CASH_OUT_FLOW_FLAT_RATE_METHODS = [
  { value: "IncludingVATAndCommission", text: "Including VAT & Commission" },
  { value: "ExcludingVATAndCommission", text: "Excluding VAT & Commission" },
];
export const COMMISION_CALCULATION_METHODS = [
  { value: "PercentageOfFinancedAmount", text: "% of Financed Amount" },
  { value: "PercentageOfInterestIncome", text: "% of Interest Income" },
];

export const Fee_FREQUENCIES = [
  { value: "OneTime", text: "One Time" },
  { value: "Periodic", text: "Periodic" },
];
export const FEE_OCCURANCES_ONE_TIME = [
  { value: "OnStartDate", text: "On Start Date" },
  { value: "WithFirstPayment", text: "With 1st Regular Payment" },
  { value: "WithLastPayment", text: "With Last Regular Payment" },
  { value: "Annual", text: "Annual" },
];
export const FEE_OCCURANCE_PERIODIC = [
  { value: "WithEveryPayment", text: "With Every Payment" },
  { value: "Monthly", text: "Monthly" },
  { value: "Quarterly", text: "Quarterly" },
  { value: "SemiAnnual", text: "Semi-Annual" },
  { value: "Annual", text: "Annual" },
];
export const FEE_OCCURANCE = [
  { value: 1, text: "With Every Payment" },
  { value: 1, text: "Monthly" },
  { value: 3, text: "Quarterly" },
  { value: 6, text: "Semi-Annual" },
  { value: 12, text: "Annual" },
];
export const PAYMENT_MODES = [
  { value: "Advance", text: "Advance" },
  { value: "Arrear", text: "Arrear" },
] as const;

export const PAYMENT_FREQUENCIES = [
  { value: "Weekly", text: "Weekly" },
  { value: "Fortnightly", text: "Fortnightly" },
  { value: "Monthly", text: "Monthly" },
  { value: "Quarterly", text: "Quarterly" },
  { value: "SemiAnnual", text: "Semi-Annual" },
  { value: "Annual", text: "Annual" },
];

export const RATE_TYPES = [
  { value: "Nominal Rate", text: "Nominal Rate" },
  { value: "APR", text: "APR" },
  { value: "Flat Rate", text: "Flat Rate" },
];

export const ANNUITY_RATE_TYPES = [
  { value: "Nominal Rate", text: "Nominal Rate" },
  { value: "Flat Rate", text: "Flat Rate" },
];

export const ADD_FEES_DEFAULT_VALUES = {
  amount: "",
  firstDueDate: "",
  type: "",
  name: "",
  frequency: "",
  paymentNumber: "",
  isEditable: true,
  firstFeePaymentDueAtStartDate: true,
};
