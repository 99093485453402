import {
  COMPANY_PROFILE,
  GET_ALL_COUNTRIES,
  THEME_BUILDER,
  GET_ASSET_CATEGORIES,
  GET_ASSET_TYPES,
  GET_ASSET_SUB_TYPES,
  GET_ASSET_CONDITIONS,
  GET_PRODUCT_TYPES,
  GET_FINANCE_TYPES,
  GET_CLIENT_TYPES,
} from "@helpers/endpoints";
import { indexAPI } from "./instances";

export const getAllCountries = async () => {
  return indexAPI.get(GET_ALL_COUNTRIES).then((response) => response.data);
};

export const getThemeBuilderByEmail = async (userEmail: string) => {
  return await indexAPI
    .get(`${THEME_BUILDER}?user_email=${userEmail}`)
    .then((res: any) => {
      return res.data;
    });
};

export const getCompanyByTenantId = async (tenant_id: number) => {
  return await indexAPI
    .get(`${COMPANY_PROFILE}/${tenant_id}`)
    .then((res: any) => {
      return res.data;
    });
};

export const getAssetCategories = async (companyId: number) => {
  return await indexAPI
    .get(GET_ASSET_CATEGORIES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getAssetTypes = async (companyId: number) => {
  return await indexAPI
    .get(GET_ASSET_TYPES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getAssetSubTypes = async (companyId: number) => {
  return await indexAPI
    .get(GET_ASSET_SUB_TYPES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getAssetConditions = async (companyId: number) => {
  return await indexAPI
    .get(GET_ASSET_CONDITIONS, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getProductTypes = async (companyId: number) => {
  return await indexAPI
    .get(GET_PRODUCT_TYPES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getFinanceTypes = async (companyId: number) => {
  return await indexAPI
    .get(GET_FINANCE_TYPES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getClientTypes = async (companyId: number) => {
  return await indexAPI
    .get(GET_CLIENT_TYPES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};
