import { LayoutWithSideNavComponent, Snackbar } from "@components";
import { useTheme } from "@mui/material";
import { ProposalPageWrap } from "./proposal-page.style";
import { Proposal } from "@components/proposal";
import { useGetProposal } from "@hooks/queries";
import { useParams, useLocation } from "react-router-dom";
import { ConfigurationWrapper as ConfigurationContextProvider } from "@components";

export const ProposalPage = () => {
  const theme = useTheme();
  const params = useParams<{ proposalId?: string }>();
  const location = useLocation();
  const redirectFromTrackProposals = location.state;
  const cloneProposalId = redirectFromTrackProposals
    ? redirectFromTrackProposals.proposalId
    : null;
  const isEditMode = !!params.proposalId || !!cloneProposalId;
  const proposalSummaryPageReference = redirectFromTrackProposals
    ? redirectFromTrackProposals.summary
    : false;
  const creditDocumentPageReference = redirectFromTrackProposals
    ? redirectFromTrackProposals.credit_documents
    : false;
  const { data, isSuccess } = useGetProposal(
    params.proposalId || cloneProposalId
  );

  if (cloneProposalId && data) data.proposal.documents = [];

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ProposalPageWrap theme={theme} className="proposal-page-wrap">
        {(!isEditMode || isSuccess) && (
          <ConfigurationContextProvider
            retainProductType={data?.proposal.proposalDetails.productType}
          >
            <Proposal
              proposalSummaryPageReference={proposalSummaryPageReference}
              creditDocumentPageReference={creditDocumentPageReference}
              proposalData={data}
            />
          </ConfigurationContextProvider>
        )}
        <Snackbar />
      </ProposalPageWrap>
    </LayoutWithSideNavComponent>
  );
};
