import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { Router } from "@router";
import { BROKER_APP_THEME, BROKER_APP_THEME_WRAP } from "styles";
import { SnackbarContextProvider } from "@contexts/snackbar";
import {
  LoginValidatorComponent,
  UMSConfigurationComponent,
} from "@ntpkunity/controls-ums";
import { useEffect, useState } from "react";
import { setFavicon, setLogo, setStyleSheet } from "@helpers/theme";
import { useGetCompanyByTenantId, useGetThemebyEmail } from "@hooks/queries";
import {
  useAssetSetups,
  useClientTypesSetup,
  useProposalDetailsSetups,
} from "@hooks/proposal";
import { CircleLoader, Typography } from "@ntpkunity/controls";

export default function Root(props) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        retry: 1,
      },
    },
  });
  return (
    <UMSConfigurationComponent>
      <LoginValidatorComponent theme={BROKER_APP_THEME}>
        <QueryClientProvider client={queryClient}>
          <App {...props} />
        </QueryClientProvider>
      </LoginValidatorComponent>
    </UMSConfigurationComponent>
  );
}
const App = () => {
  const [userEmail, setUserEmail] = useState<string>("");
  const {
    data: companyInfo,
    isLoading: companyInfoLoading,
    isError: isErrorInCompanyInfo,
  } = useGetCompanyByTenantId();
  const { data: theme } = useGetThemebyEmail(userEmail);

  // *** Setups calls to load setups on root level
  useAssetSetups();
  useProposalDetailsSetups();
  useClientTypesSetup();
  // ***

  useEffect(() => {
    if (companyInfo) {
      setUserEmail(companyInfo?.email);
    }
  }, [companyInfo]);

  useEffect(() => {
    if (theme) {
      setStyleSheet(theme?.stylesheet);
      setFavicon(theme?.favicon?.location);
      setLogo(theme?.logo?.location);
    }
  }, [theme]);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={BROKER_APP_THEME}>
        <BROKER_APP_THEME_WRAP
          className="global-theme-wrap"
          theme={BROKER_APP_THEME}
        >
          <SnackbarContextProvider>
            {companyInfoLoading ? (
              <CircleLoader theme={BROKER_APP_THEME} />
            ) : isErrorInCompanyInfo ? (
              <>Oops! Something went wrong</>
            ) : (
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            )}
          </SnackbarContextProvider>
        </BROKER_APP_THEME_WRAP>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
