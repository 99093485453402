import { useState } from "react";
import {
  Footer,
  LayoutWithSideNavComponent,
  StepperProgress,
  ComplianceDocument,
  ComplianceAddSignatories,
  ComplianceDocumentPageHeader,
} from "@components";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { WorkQueueTabs } from "@helpers/enum";
import { useTheme } from "@mui/material";
import { PageWrap } from "./compliance-document-page.style";
import { Button, Icon } from "@ntpkunity/controls";

export const ComplianceDocumentPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(0);

  // Function to handle previous button click
  const handlePrevButtonClick = () => {
    setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  // Define step items and content for each step
  const stepItems = [
    { title: "Compliance Documents" },
    { title: "Add Signatories" },
  ];

  const children = [
    <ComplianceDocument key="compliance-document" />, // Content for step 1
    <ComplianceAddSignatories key="compliance-add-signatories" />, // Content for step 2
  ];

  const getActionButtonText = () => {
    switch (activeIndex) {
      case 0:
        return "Next: Add Signatories";
      case 1:
        return "Generate Documents";
      default:
        return "Next";
    }
  };

  // Function to handle the back button click
  const handleBackButtonClick = () => {
    if (activeIndex > 0) {
      setActiveIndex((prevIndex) => prevIndex - 1); // Move to previous step
    } else {
      // Redirect to the specified URL if on the first tab
      navigate(`${APP_ROUTES.WORKQUEUE}/${WorkQueueTabs.TRACK_PROPOSALS}`);
    }
  };

  // Function to handle the action button click
  const handleActionButtonClick = () => {
    if (activeIndex < stepItems.length - 1) {
      // Move to the next step if not on the last step
      setActiveIndex((prevIndex) => prevIndex + 1);
    } else {
      // Redirect to the specified URL if on the first tab
      navigate(`${APP_ROUTES.WORKQUEUE}/${WorkQueueTabs.TRACK_PROPOSALS}`);
    }
  };

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <PageWrap theme={theme} className="page-wrap">
        <ComplianceDocumentPageHeader />
        <StepperProgress
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          onPrevButtonClick={handlePrevButtonClick}
          stepItems={stepItems}
          children={children}
          stepTitle={stepItems[activeIndex]?.title}
          nextStepTitle={
            activeIndex < stepItems.length - 1
              ? stepItems[activeIndex + 1]?.title
              : null
          }
          hideStepItems={false} // Show step items; set to true if you want to hide them
          actionArea={
            <>
              <Button
                defaultBtn
                theme={theme}
                onClick={() => {}}
                iconText={<Icon name="SaveDiskIcon" />}
              />
            </>
          }
        />

        <Footer
          actionButtonText={getActionButtonText()}
          actionButtonOnClick={() => handleActionButtonClick()}
          showBackButton
          backButtonOnClick={handleBackButtonClick}
        />
      </PageWrap>
    </LayoutWithSideNavComponent>
  );
};
