import { SAVE_CONFIGURATION } from "@helpers/endpoints";
import { indexAPI } from "@services/instances";
import { useMutation } from "react-query";

export const useSaveFinancialConfigurations = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const { financeType, ...restPayload } = body;

      return indexAPI.post(`${SAVE_CONFIGURATION}`, {
        finance_type: financeType,
        config: JSON.stringify(restPayload),
      });
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};
