import { OrderStatus } from "@helpers/enum";
import { clsx } from "clsx";

const ProposalStatus = ({ status }: { status: OrderStatus }) => {
  return (
    <>
      <span
        className={clsx("status-indicator", {
          "ind-danger": [OrderStatus.WITHDRAWN, OrderStatus.DECLINED].includes(
            status
          ),
          "ind-success": status === OrderStatus.ACCEPTED,
          "ind-warning": status === OrderStatus.CONDITIONED,
          "ind-warning-dark": status === OrderStatus.DOCUMENT_SENT,
          "ind-primary-light": status === OrderStatus.DOCUMENT_RECEIVED,
          "ind-primary-dark": status === OrderStatus.SENT_FOR_PAYOUT,
          "ind-purple": status === OrderStatus.PAID_OUT,
          "ind-contract": [OrderStatus.DRAFT, OrderStatus.SUBMITTED].includes(
            status
          ),
          "ind-info": status === OrderStatus.AWAITING_COMPLIANCE,
          "ind-complied": status === OrderStatus.COMPLIED,
        })}
      ></span>
      {status}
    </>
  );
};

export default ProposalStatus;
