import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Box, Button, Typography } from "@ntpkunity/controls";
import CelebrationIcon from "../../../public/assets/images/celebration-icon.gif";
import { BannerWrap } from "./banner.style";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types/forms";
import { getDownloadProposalPayload, getTemplateName } from "@helpers/utils";
import {
  QuotationForm,
  useCalculationsContext,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useDownloadDocs } from "@hooks/mutations";
import { ClientTypes } from "@helpers/enum";
import { useSnackbarContext } from "@contexts/snackbar";
import { DownloadTemplateTypes } from "@helpers/enum";
import { useGetClientTypes, useGetProductTypes } from "@hooks/queries";

export const Banner = () => {
  const theme = useTheme();
  const { watch, getValues: getProposalValues } =
    useFormContext<TProposalForm>();
  const { getValues: getFinanceDetailsValues } =
    useQuotationFormContext<QuotationForm>();
  const { data: calculations } = useCalculationsContext();
  const { mutateAsync: downloadDocument } = useDownloadDocs();
  const { setSnackbar } = useSnackbarContext();

  const {
    isLoading: clientTypesLoading,
    isError: isErrorInClientTypes,
    data: clientTypes,
  } = useGetClientTypes();
  const {
    isLoading: productTypesLoading,
    isError: isErrorInProductTypes,
    data: productTypes,
  } = useGetProductTypes();

  const disableDownload =
    clientTypesLoading ||
    isErrorInClientTypes ||
    productTypesLoading ||
    isErrorInProductTypes;

  const referenceNumber = watch("identifier");
  const productType = watch("proposalDetails.productType");
  const clientType = watch("customerDetails.clientType");
  const name = watch("proposalDetails.name");

  const handleDownloadProposal = async () => {
    try {
      const dataPlaceholders = getDownloadProposalPayload(
        getProposalValues(),
        calculations,
        getFinanceDetailsValues()
      );

      const clientTypesName = clientTypes.find(
        (ct) => ct.code === clientType
      )?.description;

      const productTypeName = productTypes.find(
        (pt) => pt.code === productType
      )?.name;

      const templateType =
        clientTypesName === ClientTypes.INDIVIDUAL
          ? DownloadTemplateTypes.PROPOSAL_INDIVIDUAL
          : DownloadTemplateTypes.PROPOSAL_COMPANY;
      await downloadDocument({
        data_placeholders: dataPlaceholders,
        template_type: templateType,
        template_name: getTemplateName(templateType, productTypeName),
        generate_base64: false,
        is_bookmark: false,
      });
    } catch (err) {
      setSnackbar(
        {
          open: true,
          message:
            err.response?.data?.detail ||
            "There was an error while downloading proposal",
          variant: "error",
        },
        5000
      );
    }
  };

  return (
    <Stack paddingXs={3} paddingMd={5}>
      <BannerWrap theme={theme} className="banner-wrap">
        <img src={CelebrationIcon} alt="CelebrationIcon" />
        <Box theme={theme}>
          <Typography theme={theme} component="h2" variant="h2">
            Congratulations
          </Typography>
          <Typography
            mt={2}
            theme={theme}
            component="span"
            variant="body2"
            className="text-muted"
            display={"block"}
          >
            Your proposal <span className="fw-medium text-dark">{name}</span>{" "}
            reference number{" "}
            <span className="fw-medium text-dark">{referenceNumber}</span> has
            been submitted to the broker for the review. Our representative will
            be in touch shortly to update you on progress.
          </Typography>
          <Box theme={theme} className="actions" mt={3}>
            <Button theme={theme} secondary text="Upload Documents" />
            <Button
              theme={theme}
              primary
              text="Download Proposal"
              disabled={disableDownload}
              onClick={handleDownloadProposal}
            />
          </Box>
        </Box>
      </BannerWrap>
    </Stack>
  );
};
