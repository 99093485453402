import {
  CURRENCY_CODES,
  LOCAL_STORAGE_KEYS,
  LOCALES,
  PROPOSAL_AGE_CATEGORY,
  PROPOSAL_OPTION_TEXT_MAP,
  PROPOSAL_STATUS_TO_ROW_OPTION_MAP,
  PROPOSAL_STATUSES_BY_CATEGORY,
} from "./const";
import {
  BrokerQuotationForm,
  BrokerSpecifcFormFields,
  ChartBaseData,
  DashboardAnalyticsResponse,
  TCustomer,
  TCustomerDetails,
  TOrderStakeholders,
  TProposalForm,
  TProposalWorkQueueEntry,
} from "@_types";
import {
  ClientTypes,
  DownloadTemplateTypes,
  OrderStatus,
  OrderStatusCategory,
  ProposalAgeCategories,
  ProposalStakeholders,
  SessionStorageKeys,
  TableRowOptions,
} from "./enum";
import {
  TDealerNames,
  TLenderNames,
} from "@components/proposal/proposal-details/types";
import {
  CalculationResults,
  FeeName as ControlFeeName,
  getPaymentMode,
  QuotationForm,
  TProductType,
} from "@ntpkunity/controls-common";
import { DownloadQuotationRequestParams } from "@_types/quotation";
import { Theme } from "@mui/material";
import { CommonSetupProperties, ProductTypeSetupRes } from "@_types/setups";

export const getUserSettings = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS) || "{}");

export const getUserNameInitials = (userName: string) => {
  const namePart = userName?.trim().split(/\s+/) ?? [];
  return namePart?.map((part) => part.charAt(0).toUpperCase()).join("");
};

export const getSelectValues = <T extends string>(
  arr: ReadonlyArray<T>,
  disabledInfo?: Partial<{ [key in T]: boolean }>
): { text: T; value: string; disabled?: boolean }[] => {
  return arr.map((val: T) => {
    const returnObj = { text: val, value: val };
    if (disabledInfo && disabledInfo[val]) {
      return { ...returnObj, disabled: true };
    }
    return returnObj;
  });
};

export const pounds = Intl.NumberFormat(LOCALES.GB, {
  style: "currency",
  currency: CURRENCY_CODES.GBP,
});

export const getQueryParamsString = <T extends {} = { [key: string]: string }>(
  params: T
) => {
  const queryString = Object.keys(params)
    .filter((key) => !!params[key])
    .map((key) => {
      if (Array.isArray(params[key])) {
        if (params[key].length) {
          const arrayParams = params[key].map((ele) => {
            return `${key}=${encodeURIComponent(ele)}`;
          });

          return arrayParams.join("&");
        } else {
          return null;
        }
      }
      return `${key}=${encodeURIComponent(params[key])}`;
    })
    .filter(Boolean)
    .join("&");
  return queryString ? `?${queryString}` : "";
};

export const utcToLocalDateTime = (
  dateString: string
): { dateStr: string; timeStr: string } => {
  let dateISO: string;
  if (dateString) {
    dateISO =
      dateString.replace(" ", "T") + (dateString.includes("Z") ? "" : "Z");
  } else {
    dateISO = new Date().toISOString();
  }
  const date = new Date(dateISO);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formatterDate = new Intl.DateTimeFormat("en-US", dateOptions);
  const formatterTime = new Intl.DateTimeFormat("en-US", timeOptions);
  const dateStr = formatterDate.format(date);
  let timeStr = formatterTime.format(date);
  timeStr = timeStr.replace(/AM|PM/, (match) => match.toLowerCase());
  return { dateStr, timeStr };
};

export const convertIsoDateStrToddmmyyyy = (date: string) => {
  if (!date) return date;
  return date.slice(0, 10).split("-").reverse().join("/");
};

export const convertIsoDateStrToddmmyyyyhms = (date: string) => {
  if (!date) return date;
  const dateObj = new Date(date);
  return `${dateObj
    .toISOString()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join(
      "/"
    )} ${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
};
export const convertDateToISOString = (date: Date): string => {
  if (date.getFullYear() !== 1969 && date.getFullYear() !== 1970) {
    return date.toISOString();
  }
};

export const deleteNDays = (days: number) => {
  const date = new Date(Date.now());
  date.setDate(date.getDate() - days);
  return date;
};

export const convertFromSecondsToDays = (seconds: number) => {
  return Math.round(seconds / 86400);
};
export const getCustomerName = (customer: TCustomer) => {
  if (!customer || !customer.customer_type) return "";
  return customer.customer_type === ClientTypes.INDIVIDUAL
    ? `${customer.first_name || ""} ${customer.last_name || ""}`
    : customer.company_name;
};

export const getOrderStakeholders = (
  orderStakeholders: TOrderStakeholders
): { lenderName: TLenderNames; dealerName: TDealerNames } => {
  return {
    lenderName: orderStakeholders?.find(
      (stakeholder) => stakeholder.role === ProposalStakeholders.LENDER
    )?.name as TLenderNames,
    dealerName: orderStakeholders?.find(
      (stakeholder) => stakeholder.role === ProposalStakeholders.DEALER
    )?.name as TDealerNames,
  };
};

export const alphaNumericMask = (value: string) =>
  Array.from(value).map((_) => /[a-zA-Z0-9 ]/);

export const errorInQuotationDetails = (
  formValues: BrokerSpecifcFormFields
): { isError: true; message: string } | { isError: false } => {
  const errorValues: string[] = [];
  if (!formValues.name?.trim()) {
    errorValues.push("Quotation name");
  }
  if (!formValues.clientType) {
    errorValues.push("Client Type");
  }
  if (!formValues.asset?.type) {
    errorValues.push("Asset Type");
  }

  if (errorValues.length) {
    let errorMsg = "";
    errorValues.forEach((value, index) => {
      errorMsg = errorMsg + (index === 0 ? value : ` and ${value} `);
    });
    errorMsg += " cannot be empty";
    return { isError: true, message: errorMsg };
  }
  return { isError: false };
};

export const getQuotationDownloadPayload = (
  formValues: BrokerQuotationForm,
  calculations: CalculationResults
): DownloadQuotationRequestParams => {
  const findFeeByName = (name: ControlFeeName) =>
    formValues.fees.find((fee) => fee.name === name);

  return {
    name: formValues.name,
    broker_name: formValues.brokerName,
    asset_type: formValues.asset.type,
    client_type: formValues.clientType,
    asset_cost: pounds.format(Number(formValues.assetCost)),
    deposit_amount: pounds.format(Number(calculations.depositAmount)),
    commission_amount: pounds.format(Number(calculations.commissionAmount)),
    rate: `${Number(formValues.rate).toFixed(2)}%`,
    rate_type: formValues.rateType,
    no_of_advance_payment: formValues.noOfAdvancePayments,
    no_of_regular_payments: formValues.noOfRegularPayments,
    balloon_payment: pounds.format(Number(formValues.balloonPayment)),
    ballon_collection: formValues.balloonCollection,
    vat_treatment: formValues.vatType,
    vat_amount: pounds.format(Number(formValues.vatAmount)),
    vat_number: formValues.vatNumber,
    vat_deferred_type: formValues.vatDeferredType,
    document_fee: pounds.format(
      Number(findFeeByName(ControlFeeName.DOC_FEE)?.amount)
    ),
    annual_admin_fee: pounds.format(
      Number(findFeeByName(ControlFeeName.ANNUAL_ADMIN_FEE)?.amount)
    ),
    otp_fee: pounds.format(
      Number(findFeeByName(ControlFeeName.OTP_FEE)?.amount)
    ),
    payment_frequency: formValues.paymentFrequency,
    payment_mode: getPaymentMode(formValues.noOfAdvancePayments),
    finance_amount: pounds.format(Number(calculations.financeAmount)),
    sum_of_advance_rentals: pounds.format(
      Number(calculations.sumOfAdvanceRentals)
    ),
    sum_of_fees: pounds.format(Number(calculations.sumOfFees)),
    sum_of_periodic_interest: pounds.format(
      Number(calculations.sumOfPeriodicInterest)
    ),
    sum_of_rentals: pounds.format(Number(calculations.sumOfRentals)),
    total_payables: pounds.format(Number(calculations.totalPayables)),
    gross_yield: `${calculations.rates.grossYield.toFixed(2)}%`,
    net_yield: `${calculations.rates.netYield.toFixed(2)}%`,
    apr: `${calculations.rates.apr.toFixed(2)}%`,
    flat_rate_excl_commission: `${calculations.rates.flatRateExclCommission.toFixed(
      2
    )}%`,
    flat_rate_incl_commission: `${calculations.rates.flatRateInclCommission.toFixed(
      2
    )}%`,
    term:
      Number(formValues.noOfRegularPayments) +
      (Number(formValues.balloonPayment) > 0
        ? Number(formValues.balloonCollection)
        : 0),
  };
};

export const getBrokerQuotationPayload = (
  quotationPayload: any,
  brokerFields: BrokerSpecifcFormFields,
  forUpdate = false
) => {
  const additionalQuoteDetails = {
    introducer_name: brokerFields.brokerName,
    name: brokerFields.name,
    assets: [
      {
        identifier: forUpdate ? brokerFields.asset.identifier : undefined,
        asset_type: brokerFields.asset.type,
      },
    ],
  };
  if (forUpdate) {
    return {
      ...quotationPayload,
      ...additionalQuoteDetails,
      customer: {
        ...quotationPayload.customer,
        customer_type: brokerFields.clientType,
      },
      updated_by: getUserSettings()?.user_name || "",
    };
  } else {
    return {
      ...quotationPayload,
      quote_details: {
        ...quotationPayload.quote_details,
        ...additionalQuoteDetails,
        created_by: getUserSettings()?.user_name || "",
      },
      quote_identifiers: {
        ...quotationPayload.quote_identifiers,
        customer_type: brokerFields.clientType,
      },
    };
  }
};

export const getStatusColorMap = (theme: Theme) => {
  return {
    [OrderStatus.ACCEPTED]: theme.palette.success.main,
    [OrderStatus.WITHDRAWN]: theme.palette.error.main,
    [OrderStatus.DECLINED]: theme.palette.error.main,
    [OrderStatus.CONDITIONED]: theme.palette.warning.main,
    [OrderStatus.DOCUMENT_RECEIVED]: "#40CBE0",
    [OrderStatus.SENT_FOR_PAYOUT]: "#5E5CE6",
    [OrderStatus.PAID_OUT]: "#BF5AF2",
    [OrderStatus.DRAFT]: theme.palette.info.main,
    [OrderStatus.SUBMITTED]: theme.palette.info.main,
    [OrderStatus.DOCUMENT_SENT]: "#FFD60A",
    [OrderStatus.AWAITING_COMPLIANCE]: "#AC8E68",
    [OrderStatus.COMPLIED]: "#63E6E2",
  };
};

export const getCategoryColorMap = (theme: Theme) => {
  return {
    [OrderStatusCategory.ACTIVE]: theme.palette.info.main,
    [OrderStatusCategory.CLOSED]: theme.palette.error.main,
  };
};

export const getActiveProposalBaseData = (theme: Theme): ChartBaseData[] => {
  const colorMap = getStatusColorMap(theme);
  return PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.ACTIVE].map(
    (status) => {
      return {
        status: status,
        color: colorMap[status],
        count: 0,
      };
    }
  );
};

export const getProposalCategoryBaseData = (theme: Theme): ChartBaseData[] => {
  const colorMap = getCategoryColorMap(theme);
  return [OrderStatusCategory.ACTIVE, OrderStatusCategory.CLOSED].map(
    (status) => {
      return {
        status: status,
        color: colorMap[status],
        count: 0,
      };
    }
  );
};

export const getStatusCount = (
  proposals: DashboardAnalyticsResponse[],
  statuses: OrderStatus[]
) => {
  const statusToCount = statuses.reduce((prev, status) => {
    prev[status] = 0;
    return prev;
  }, {});

  proposals.forEach((dataItem) => {
    if (statusToCount[dataItem.status] !== undefined) {
      statusToCount[dataItem.status] = statusToCount[dataItem.status] + 1;
    }
  });

  return statusToCount;
};

export const reduceDataForProposalWidget = (
  theme: Theme,
  proposals: DashboardAnalyticsResponse[] | undefined
) => {
  if (!proposals) {
    return [];
  }
  const baseData = getActiveProposalBaseData(theme);
  const statusToCount = getStatusCount(
    proposals,
    PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.ACTIVE]
  );
  return baseData.map((baseData) => {
    return {
      ...baseData,
      count: statusToCount[baseData.status],
    };
  });
};

export const reduceDataForTotalProposalWidget = (
  theme: Theme,
  proposals: DashboardAnalyticsResponse[]
) => {
  if (!proposals) {
    return [];
  }

  const activeStatusToCounts = getStatusCount(
    proposals,
    PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.ACTIVE]
  );

  const totalActiveCounts = Object.keys(activeStatusToCounts).reduce(
    (prev, cur) => prev + activeStatusToCounts[cur],
    0
  );
  const closedStatusToCount = getStatusCount(
    proposals,
    PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.CLOSED]
  );
  const totalClosedCounts = Object.keys(closedStatusToCount).reduce(
    (prev, cur) => prev + closedStatusToCount[cur],
    0
  );

  const baseData = getProposalCategoryBaseData(theme);

  const categoryToCount = {
    [OrderStatusCategory.ACTIVE]: totalActiveCounts,
    [OrderStatusCategory.CLOSED]: totalClosedCounts,
  };

  return baseData.map((baseData) => {
    return {
      ...baseData,
      count: categoryToCount[baseData.status],
    };
  });
};

export const reduceDataForAgingProposalWidget = (
  proposals: DashboardAnalyticsResponse[]
) => {
  if (!proposals) {
    return [];
  }
  if (proposals?.length > 0) {
    const data = proposals.reduce(
      (acc, proposal) => {
        const proposalAge = convertFromSecondsToDays(
          Number(proposal.age_in_seconds ?? 0)
        );

        const range = Object.keys(PROPOSAL_AGE_CATEGORY).find((range) => {
          const [min, max] =
            PROPOSAL_AGE_CATEGORY[range as ProposalAgeCategories];
          return proposalAge >= min && proposalAge <= max;
        }) as ProposalAgeCategories;

        if (range) {
          acc[range].push(proposal);
        }
        return acc;
      },
      {
        [ProposalAgeCategories.LESS_THAN_TWO]: [],
        [ProposalAgeCategories.THREE_TO_FIVE]: [],
        [ProposalAgeCategories.SIX_TO_TEN]: [],
        [ProposalAgeCategories.MORE_THAN_TEN]: [],
      } as { [key in ProposalAgeCategories]: any }
    );

    return Object.entries(data).map((obj) => {
      return { category: obj[0], count: obj[1].length ?? 0 };
    });
  }
};

export const getProposalRowOptions = (
  proposal: TProposalWorkQueueEntry,
  disableDownload: boolean
) => {
  const { status } = proposal;
  const availableOptions = PROPOSAL_STATUS_TO_ROW_OPTION_MAP[status] || [];

  return availableOptions.map((optionKey) => ({
    optionText: PROPOSAL_OPTION_TEXT_MAP[optionKey],
    optionkey: optionKey,
    optionValue: proposal,
    disabled: optionKey === TableRowOptions.DOWNLOAD ? disableDownload : false,
  }));
};

export const getFormattedAddress = (address: TCustomerDetails["address"]) => {
  const items = [
    address.addressLine1,
    address.addressLine2,
    address.city,
    address.county,
    address.zipCode,
  ].filter(Boolean);
  return items.reduce((prev, cur, index) => {
    if (index) return `${prev}, ${cur}`;
    return cur;
  }, "");
};

export const getDownloadProposalPayload = (
  proposalValues: TProposalForm,
  financialCalculations: CalculationResults,
  financialDetails: QuotationForm
) => {
  const customerDetails = proposalValues.customerDetails;
  const accountSettings = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS)
  );
  const assetCost = proposalValues.assets.reduce(
    (total, asset) => total + Number(asset.totalCost),
    0
  );
  return {
    client_type: customerDetails.clientType,
    first_name: customerDetails.firstName,
    last_name: customerDetails.lastName,
    contact_number: customerDetails.contactNumber,
    email_address: customerDetails.emailAddress,
    id_number: customerDetails.idNumber,
    company_name: customerDetails.companyName,
    registration_number: customerDetails.companyName,
    trading_as: customerDetails.tradingAs,
    years_in_business: customerDetails.yearsInBusiness,
    address: getFormattedAddress(customerDetails.address),
    broker_name: proposalValues.proposalDetails.brokerName,
    introducer_name: accountSettings?.user_name,
    introducer_email: accountSettings?.email,
    introducer_contact: "", // Need to look into this
    asset_cost: pounds.format(assetCost || 0),
    deposit: pounds.format(financialCalculations.depositAmount || 0),
    finance_amount: pounds.format(financialCalculations.financeAmount || 0),
    advance_payments: financialDetails.noOfAdvancePayments,
    regular_payments: financialDetails.noOfRegularPayments,
    payment_frequency: financialDetails.paymentFrequency,
    payment_mode: getPaymentMode(financialDetails.noOfAdvancePayments),
    total_payable: pounds.format(financialCalculations.totalPayables || 0),
    gross_yield: `${financialCalculations.rates.grossYield.toFixed(2)}%`,
    net_yield: `${financialCalculations.rates.netYield.toFixed(2)}%`,
    apr: `${financialCalculations.rates.apr.toFixed(2)}%`,
    flat_rate_excl_commission: `${financialCalculations.rates.flatRateExclCommission.toFixed(
      2
    )}%`,
    flat_rate_incl_commission: `${financialCalculations.rates.flatRateInclCommission.toFixed(
      2
    )}%`,
    fees_info:
      financialDetails.fees?.map((fee) => {
        return {
          amount: pounds.format(fee.amount || 0),
          frequency: fee.frequency,
          name: fee.name,
          occurence: fee.type,
        };
      }) || [],
    director_details:
      customerDetails.directorDetails?.map((director) => {
        return {
          full_name: `${director.firstName ?? ""} ${director.lastName ?? ""}`,
          email: director.emailAddress,
          contact_number: director.contactNumber,
          address: director.address,
        };
      }) || [],
    assets:
      proposalValues.assets?.map((asset) => {
        return {
          cost: pounds.format(asset.cost),
          quantity: asset.quantity,
          sub_type: asset.subType,
          supplier_name: asset.supplierName,
        };
      }) || [],
    credit_documents:
      proposalValues.documents?.map((doc) => {
        return {
          name: doc.name,
          type: doc.type,
          state: "Mandatory",
          status: doc.documents.length > 0 ? "Uploaded" : "Missing",
        };
      }) || [],
  };
};

export const getNumberOfYearsPassed = (date: Date) => {
  const currentDate = new Date();
  let noOfYears = currentDate.getFullYear() - date.getFullYear();
  if (
    currentDate.getMonth() < date.getMonth() ||
    (currentDate.getMonth() === date.getMonth() &&
      currentDate.getDate() < date.getDate())
  ) {
    noOfYears--;
  }
  return noOfYears;
};

export const setSessionStorageItem = <TData>(
  key: SessionStorageKeys,
  data: TData
) => {
  const dataString = JSON.stringify(data);
  sessionStorage.setItem(key, dataString);
};

export const getSessionStorageItem = <TData>(
  key: SessionStorageKeys
): TData | undefined => {
  const dataString = sessionStorage.getItem(key);
  if (!dataString) {
    return undefined;
  }
  return JSON.parse(dataString);
};

export const removeSessionStorageItem = (key: SessionStorageKeys) =>
  sessionStorage.removeItem(key);

export const getTemplateName = (
  templateType: DownloadTemplateTypes,
  productType: string
) => {
  return `${templateType}_${productType
    .toUpperCase()
    .split(" ")
    .join("_")}.docx`;
};

export const removeDeletedEntities = <T extends CommonSetupProperties>(
  dontRemoveValue: string,
  options: T[]
) => {
  return options?.filter(
    (option) =>
      (dontRemoveValue && option.code === dontRemoveValue) || !option.is_deleted
  );
};

export const getProductTypePayload = (
  dontRemove: string,
  productTypes: ProductTypeSetupRes
): TProductType[] => {
  return removeDeletedEntities(dontRemove, productTypes)?.map((type) => ({
    code: type.code,
    name: type.name,
  }));
};
