import { createRef, RefObject, useState } from "react";
import {
  Stack,
  Footer,
  ShowInputFieldsButton,
  SingleQuotation,
  QuotationComparisonPageHeader,
} from "@components";
import {
  CalculationResponse,
  CalculationsContextProvider,
  convertCalculationResponse,
  QuotationForm,
  QuotationFormProvider,
  useCalculateQuote,
} from "@ntpkunity/controls-common";
import { Box, Button, Grid, Icon } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import {
  ComparisonViewMode,
  PromiseStates,
  SessionStorageKeys,
} from "@helpers/enum";
import { createId } from "@paralleldrive/cuid2";
import {
  MAX_NUMBER_OF_QUOTATIONS,
  MIN_NUMBER_OF_QUOTATIONS,
} from "@helpers/const";
import { QuotationHandle } from "@_types/quotation";
import { PageContent } from "@pages/quote-comparison/quote-comparison.style";
import { useSnackbarContext } from "@contexts/snackbar";
import { useNavigate } from "react-router-dom";
import { getSessionStorageItem, setSessionStorageItem } from "@helpers/utils";

export type QuotationHandleRef = {
  id: string;
  ref: RefObject<QuotationHandle>;
  errorMessage?: string;
};

export const ComparisonTool = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState<ComparisonViewMode>(
    ComparisonViewMode.INPUT
  );
  const [quotationCalculated, setQuotationCalculated] = useState(false);
  const [quotationRefs, setQuotationRefs] = useState<QuotationHandleRef[]>(
    [createId(), createId()].map((id) => ({
      id: id,
      ref: createRef<QuotationHandle>(),
    }))
  );
  const [selectedQuotationRef, setSelectedQuotationRef] =
    useState<QuotationHandleRef>(quotationRefs[0]);

  const { mutateAsync: calculateQuotation } = useCalculateQuote({});
  const { setSnackbar } = useSnackbarContext();

  const isInputMode = viewMode === ComparisonViewMode.INPUT;
  const isResultMode = viewMode === ComparisonViewMode.RESULT;
  const setInputMode = () => setViewMode(ComparisonViewMode.INPUT);
  const setResultsMode = () => setViewMode(ComparisonViewMode.RESULT);

  const handleDeleteQuotation = (index: number) => {
    if (quotationRefs.length > MIN_NUMBER_OF_QUOTATIONS)
      setQuotationRefs(quotationRefs.filter((_, i) => i !== index));
  };

  const onErrorClose = (refId: string) => {
    setQuotationRefs(
      quotationRefs.map((qR) =>
        qR.id === refId ? { ...qR, errorMessage: undefined } : qR
      )
    );
  };

  const pushLastQuotationDataToSS = () => {
    const lastQuotation = quotationRefs.at(quotationRefs.length - 1);
    const lastQuotationRefHandle = lastQuotation.ref.current;
    setSessionStorageItem<QuotationForm>(
      SessionStorageKeys.QUOTATION_INPUTS,
      lastQuotationRefHandle.getQuotationFormContext().getValues()
    );
  };

  const handleAddQuotation = () => {
    pushLastQuotationDataToSS();
    if (quotationRefs.length < MAX_NUMBER_OF_QUOTATIONS)
      setQuotationRefs([
        ...quotationRefs,
        { id: createId(), ref: createRef<QuotationHandle>() },
      ]);
  };
  const handleSingleCalculation = async (quotationRef: QuotationHandleRef) => {
    const refHandle = quotationRef.ref.current;
    const payload = refHandle.getCalculateQuotePayload();
    const { setCalculationCallInProgress, setCalculationResults } =
      refHandle.getCalculationActions();
    setCalculationCallInProgress();
    const response: CalculationResponse = await calculateQuotation(payload);
    setCalculationResults(convertCalculationResponse(response));
    return response;
  };

  const handleCalculateComparison = async () => {
    setQuotationRefs(quotationRefs.map((qR) => ({ id: qR.id, ref: qR.ref })));
    setSnackbar({ open: true, message: "Calculating Comparisons" });
    try {
      const responses = await Promise.allSettled(
        quotationRefs.map((quotationRef) =>
          handleSingleCalculation(quotationRef)
        )
      );

      const errorInCalculation = responses.some(
        (res) => res.status === PromiseStates.REJECTED
      );

      if (errorInCalculation) {
        setQuotationRefs(
          quotationRefs.map((qR, i) => {
            const res = responses.at(i);
            return {
              id: qR.id,
              ref: qR.ref,
              errorMessage:
                res.status === PromiseStates.REJECTED
                  ? res.reason.response.data?.Message
                  : undefined,
            };
          })
        );
        setSnackbar(
          {
            open: true,
            message: "Error while Calculating Comparison",
            variant: "error",
          },
          2000
        );
      } else {
        quotationRefs.forEach((quotationRef) => {
          const refHandle = quotationRef.ref.current;
          const { reset, getValues } = refHandle.getQuotationFormContext();
          reset(getValues());
        });
        setSnackbar(
          {
            open: true,
            message: "Comparison Calculated Successfully",
          },
          2000
        );
        setResultsMode();
        setQuotationCalculated(true);
      }
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Error while Calculating Comparison",
        variant: "error",
      });
    }
  };

  const quotationInputsData = getSessionStorageItem<QuotationForm>(
    SessionStorageKeys.QUOTATION_INPUTS
  );

  return (
    <>
      <QuotationComparisonPageHeader
        selectedQuotationRef={selectedQuotationRef}
        disableBtns={!quotationCalculated}
      />
      <PageContent theme={theme} className="page-content has-footer">
        {isResultMode && <ShowInputFieldsButton onClick={setInputMode} />}
        <Box
          theme={theme}
          className="quotation-tool-container"
          m={{ xs: "24px 0 0 0", md: "16px -24px -8px -24px" }}
        >
          <Box
            theme={theme}
            className="comparison-container"
            mt={2}
            flexGrow={1}
          >
            <Grid theme={theme} container item spacing={2} alignItems="stretch">
              {quotationRefs.map((quotationRef, index) => {
                const headerText = `Quotation ${index + 1}`;
                const handleSelectQuotation = () => {
                  setSelectedQuotationRef(
                    quotationRefs.find((ref) => ref.id === quotationRef.id)
                  );
                };
                return (
                  <CalculationsContextProvider key={quotationRef.id}>
                    <QuotationFormProvider defaultValues={quotationInputsData}>
                      <SingleQuotation
                        headerText={headerText}
                        errorMessage={quotationRef.errorMessage}
                        totalQuotations={quotationRefs.length}
                        viewMode={viewMode}
                        handleDeleteQuotation={() =>
                          handleDeleteQuotation(index)
                        }
                        isSelected={selectedQuotationRef.id === quotationRef.id}
                        onErrorClose={() => onErrorClose(quotationRef.id)}
                        handleSelectQuotation={handleSelectQuotation}
                        ref={quotationRefs[index].ref}
                      />
                    </QuotationFormProvider>
                  </CalculationsContextProvider>
                );
              })}
            </Grid>
          </Box>
          {quotationRefs.length < MAX_NUMBER_OF_QUOTATIONS &&
            viewMode !== ComparisonViewMode.RESULT && (
              <Box theme={theme} className="add-new" flexShrink={0}>
                <Button
                  type="button"
                  secondary
                  theme={theme}
                  iconText={<Icon name="AddIcon" />}
                  onClick={handleAddQuotation}
                />
              </Box>
            )}
        </Box>
        {quotationCalculated && isInputMode && (
          <Box
            theme={theme}
            margin={{ xs: "16px 0px 0px 0px", md: "24px -24px 0px -24px" }}
          >
            <Stack
              className="cp"
              paddingMd={3}
              paddingXs={3}
              title="Results"
              onClick={setResultsMode}
              actionArea={<Icon name="ChevronDown" />}
            />
          </Box>
        )}

        <Footer
          actionButtonText="Calculate"
          actionButtonOnClick={handleCalculateComparison}
          showBackButton
          backButtonOnClick={() => navigate(-1)}
        />
      </PageContent>
    </>
  );
};
