import { getProductTypePayload } from "@helpers/utils";
import { useGetProductTypes } from "@hooks/queries";
import { useTheme } from "@mui/material";
import { CircleLoader } from "@ntpkunity/controls";
import { ConfigurationContextProvider } from "@ntpkunity/controls-common";
import { useEffect } from "react";
import { useSnackbarContext } from "@contexts/snackbar";

type ConfigurationWrapperProps = {
  retainProductType?: string;
  children: React.ReactNode;
};

export const ConfigurationWrapper = ({
  retainProductType,
  children,
}: ConfigurationWrapperProps) => {
  const theme = useTheme();

  const {
    isLoading: productTypesLoading,
    isError: isErrorInProductTypes,
    data: productTypes,
  } = useGetProductTypes();

  const { setSnackbar, resetSnackbar } = useSnackbarContext();

  useEffect(() => {
    if (isErrorInProductTypes)
      setSnackbar({
        open: true,
        message: "Oops! Something went Wrong",
        variant: "error",
      });
    else resetSnackbar();
  }, [isErrorInProductTypes]);

  if (productTypesLoading) return <CircleLoader theme={theme} />;

  if (productTypes?.length)
    return (
      <ConfigurationContextProvider
        productTypes={getProductTypePayload(retainProductType, productTypes)}
      >
        {children}
      </ConfigurationContextProvider>
    );

  return <></>;
};
