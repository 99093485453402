import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu } from "@ntpkunity/controls";
import { DashboardTableRowProps } from "./dashboard-table-row.props";
import {
  pounds,
  getProposalRowOptions,
  getDownloadProposalPayload,
  getTemplateName,
} from "@helpers/utils";
import dayjs from "dayjs";
import {
  ClientTypes,
  DownloadTemplateTypes,
  OrderStatus,
  TableRowOptions,
} from "@helpers/enum";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import ProposalStatus from "./dashboard-status/dashboard-status.component";
import { TProposalWorkQueueEntry } from "@_types/workQueues";
import { getProposalByReferenceNumber } from "@services/dms.service";
import {
  mapProposalPayload,
  useGetClientTypes,
  useGetProductTypes,
} from "@hooks/queries";
import { useDownloadDocs } from "@hooks/mutations";
import { useSnackbarContext } from "@contexts/snackbar";
import { ProductTypeCell } from "@components/work-queue";

const DashboardTableRow = ({
  proposal,
  openConfirmDialog,
  openStatusDialog,
  openConditionDialog,
  openDiaryDialog,
}: DashboardTableRowProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    isLoading: clientTypesLoading,
    isError: isErrorInClientTypes,
    data: clientTypes,
  } = useGetClientTypes();
  const {
    isLoading: productTypesLoading,
    isError: isErrorInProductTypes,
    data: productTypes,
  } = useGetProductTypes();

  const { mutateAsync: downloadDocument } = useDownloadDocs();
  const { setSnackbar } = useSnackbarContext();
  const updatedAt = dayjs(proposal.updated_at, "YYYY-MM-DD");
  const dateSubmitted = proposal.date_submitted
    ? dayjs(proposal.date_submitted, "YYYY-MM-DD")
    : null;
  const disableDownload =
    clientTypesLoading ||
    isErrorInClientTypes ||
    productTypesLoading ||
    isErrorInProductTypes;
  const handleDownloadProposal = async (identifier: string) => {
    try {
      const response = await getProposalByReferenceNumber(identifier);
      const {
        proposal,
        quotation: { formValues, calculations },
      } = mapProposalPayload(response);
      const dataPlaceholders = getDownloadProposalPayload(
        proposal,
        calculations,
        formValues
      );

      const clientTypesName = clientTypes.find(
        (ct) => ct.code === proposal.customerDetails.clientType
      )?.description;

      const templateType =
        clientTypesName === ClientTypes.INDIVIDUAL
          ? DownloadTemplateTypes.PROPOSAL_INDIVIDUAL
          : DownloadTemplateTypes.PROPOSAL_COMPANY;

      const productTypeName = productTypes.find(
        (pt) => pt.code === formValues.productType
      )?.name;
      await downloadDocument({
        data_placeholders: dataPlaceholders,
        template_type: templateType,
        template_name: getTemplateName(templateType, productTypeName),
        generate_base64: false,
        is_bookmark: false,
      });
    } catch (err) {
      setSnackbar(
        {
          open: true,
          message:
            err.response?.data?.detail ||
            "There was an error while downloading proposal",
          variant: "error",
        },
        5000
      );
    }
  };

  const handleOptionClick = async (
    _,
    key: TableRowOptions,
    proposal: TProposalWorkQueueEntry
  ) => {
    if (key === TableRowOptions.OPEN_DIARY) {
      openDiaryDialog({ proposalId: proposal.identifier });
      return;
    }
    if (key === TableRowOptions.GENERATE_DOCUMENTS) {
      navigate(APP_ROUTES.DOCUMENT);
      return;
    }
    if (key === TableRowOptions.SEND_FOR_PAYOUT) {
      navigate(APP_ROUTES.SEND_FOR_PAYOUT);
      return;
    }
    if (key === TableRowOptions.CLONE) {
      navigate(APP_ROUTES.PROPOSAL, {
        state: { proposalId: proposal.identifier },
      });
      return;
    }
    if (key === TableRowOptions.CHECK_SIGNATORY_STATUS) {
      navigate(APP_ROUTES.SIGNATORY_STATUS);
      return;
    }
    if (key === TableRowOptions.VIEW_CONDITIONS) {
      openConditionDialog();
      return;
    }
    if (key === TableRowOptions.VIEW_EDIT) {
      navigate(`${APP_ROUTES.PROPOSAL}/${proposal.identifier}`);
      return;
    }
    if (key === TableRowOptions.PROPOSAL_SUMMARY) {
      navigate(`${APP_ROUTES.PROPOSAL}/${proposal.identifier}`, {
        state: { summary: true, credit_documents: false },
      });
      return;
    }
    if (key === TableRowOptions.UPLOAD) {
      navigate(`${APP_ROUTES.PROPOSAL}/${proposal.identifier}`, {
        state: { credit_documents: true, summary: false },
      });
      return;
    }
    if (key === TableRowOptions.MANUAL_STATUS_CHANGE) {
      openStatusDialog({
        identifier: proposal.identifier,
        status: proposal.status,
        name: proposal.name,
      });
      return;
    }
    if (key === TableRowOptions.WITHDRAW) {
      openConfirmDialog({
        identifier: proposal.identifier,
        status: proposal.status,
        referenceNumber: proposal.reference_number,
        name: proposal.name,
        nextStatus: OrderStatus.WITHDRAWN,
      });
      return;
    }
    if (key === TableRowOptions.DOWNLOAD) {
      await handleDownloadProposal(proposal.identifier);
    }
  };

  return (
    <TableRow className="child-tr">
      <TableCell>
        <ProposalStatus status={proposal.status} />
      </TableCell>
      <TableCell>{proposal.name}</TableCell>
      <TableCell>
        <ProductTypeCell productTypeCode={proposal.product_type} />
      </TableCell>
      <TableCell>{proposal.customer_name}</TableCell>
      <TableCell>{proposal.lender_name}</TableCell>
      <TableCell>
        {dateSubmitted ? dateSubmitted.format("DD/MM/YYYY") : null}
      </TableCell>
      <TableCell className="text-right">
        {pounds.format(proposal.finance_amount)}
      </TableCell>
      <TableCell>{updatedAt.format("DD/MM/YYYY")}</TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={getProposalRowOptions(proposal, disableDownload)}
          handleOptionClick={handleOptionClick}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default DashboardTableRow;
