import {
  FinancialConfigurations,
  LayoutWithSideNavComponent,
  ConfigurationWrapper as ConfigurationContextProvider,
} from "@components";
import { useTheme } from "@mui/material";
import { Box } from "@ntpkunity/controls";
import { PageContent } from "./financial-configurations.style";

export const FinancialConfigurationsPage = () => {
  const theme = useTheme();
  return (
    <ConfigurationContextProvider>
      <LayoutWithSideNavComponent theme={theme}>
        <PageContent theme={theme} className="page-content" pt={3}>
          <Box
            theme={theme}
            className="financial-config-container"
            m={{ xs: 0, md: "-8px -24px -8px -24px" }}
          >
            <FinancialConfigurations />
          </Box>
        </PageContent>
      </LayoutWithSideNavComponent>
    </ConfigurationContextProvider>
  );
};
