import { useQuery, UseQueryResult } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getOrderWorkqueue } from "@services/dms.service";
import { TSortParams, TWorkQueueResponse } from "@_types";

const useGetOrders = <
  SearchParams extends { [key: string]: any },
  ReturnType extends { [key: string]: any }
>(
  searchParams: SearchParams,
  sortParams: TSortParams<keyof SearchParams>
): UseQueryResult<TWorkQueueResponse<ReturnType>> => {
  return useQuery<TWorkQueueResponse<ReturnType>>({
    queryKey: [QUERY_KEYS.ORDER_WORKQUEUE, searchParams, sortParams],
    queryFn: () => getOrderWorkqueue(searchParams, sortParams),
  });
};

export default useGetOrders;
