import {
  useGetAssetCategories,
  useGetAssetConditions,
  useGetAssetSubTypes,
  useGetAssetTypes,
  useGetCompanyByTenantId,
} from "@hooks/queries";
import {
  AssetTypesSetupRes,
  AssetSubTypesSetupRes,
  AssetCategoriesSetupRes,
} from "@_types/setups";

type GroupedData<T> = { [key: string]: T[] };

function getGroupedData<K extends { id: number; code: string }, D>(
  key: keyof D,
  keyData: K[],
  data: D[]
): GroupedData<D> {
  const idToCodeMap = keyData.reduce((prev, cur) => {
    prev[cur.id] = cur.code;
    return prev;
  }, {});

  const groupedData = data.reduce((prev: GroupedData<D>, cur: D) => {
    const groupKey = String(idToCodeMap[cur[key] as number]);
    if (!prev[groupKey]) {
      prev[groupKey] = [cur];
    } else {
      prev[groupKey].push(cur);
    }
    return prev;
  }, {});

  return groupedData;
}

const getAssetTypes = (
  categories: AssetCategoriesSetupRes,
  data: AssetTypesSetupRes
) => getGroupedData("asset_category_id", categories, data);

const getAssetSubTypes = (
  types: AssetTypesSetupRes,
  data: AssetSubTypesSetupRes
) => getGroupedData("asset_type_id", types, data);

const getConcatenatedAssets = (
  categories: AssetCategoriesSetupRes,
  types: {
    [key: string]: AssetTypesSetupRes;
  },
  subTypes: {
    [key: string]: AssetSubTypesSetupRes;
  }
) => {
  const result: string[] = [];
  for (const code in categories) {
    const category = categories[code];
    const categoryName = category.description;
    const categoryTypes = types[category.code];

    if (categoryTypes) {
      for (const code in categoryTypes) {
        const type = categoryTypes[code];
        const typeName = type.name;
        const typeSubTypes = subTypes[type.code];
        if (typeSubTypes) {
          for (const code in typeSubTypes) {
            const subType = typeSubTypes[code];
            const subTypeName = subType.name;
            result.push(`${categoryName} - ${typeName} - ${subTypeName}`);
          }
        } else {
          result.push(`${categoryName} - ${typeName}`);
        }
      }
    } else {
      result.push(`${categoryName}`);
    }
  }

  return result;
};

const useAssetSetups = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();

  const companyId = companyInfo?.id;

  const categoryQuery = useGetAssetCategories(companyId);
  const typesQuery = useGetAssetTypes(companyId);
  const subTypesQuery = useGetAssetSubTypes(companyId);
  const conditionQuery = useGetAssetConditions(companyId);

  const categoriesLoaded = categoryQuery.isSuccess && categoryQuery.data.length;
  const assetCategories = categoriesLoaded ? categoryQuery.data : [];
  const assetTypesLoaded = typesQuery.isSuccess && typesQuery.data.length;
  const assetSubTypesLoaded =
    subTypesQuery.isSuccess && subTypesQuery.data.length;
  const assetTypes =
    categoriesLoaded && assetTypesLoaded
      ? getAssetTypes(assetCategories, typesQuery.data)
      : {};
  const assetSubTypes =
    assetTypesLoaded && assetSubTypesLoaded
      ? getAssetSubTypes(typesQuery.data, subTypesQuery.data)
      : {};

  const assetConditions =
    conditionQuery.isSuccess && conditionQuery.data.length
      ? conditionQuery.data
      : [];

  const concatenations =
    categoriesLoaded && assetTypesLoaded && assetSubTypesLoaded
      ? getConcatenatedAssets(assetCategories, assetTypes, assetSubTypes)
      : [];

  return {
    assetCategories,
    assetTypes,
    assetSubTypes,
    assetConditions,
    concatenations,
  };
};

export default useAssetSetups;
