import "react";
import { Accordion, Button, Icon } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { AdditionalDetailsListWrap } from "./compliance-document.style";
import { Stack } from "@components/stack";
import ComplianceDocumentContent from "./compliance-document-content.component";

const CompliacneDocumentList = () => {
  const theme = useTheme();
  return (
    <AdditionalDetailsListWrap
      theme={theme}
      className="additional-details-list-wrap"
    >
      <Stack
        className="collapse-wrap list-collapse"
        paddingMd={3}
        paddingXs={3}
        marginTopMd={2}
        marginTopXs={2}
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <>
                  <ComplianceDocumentContent />
                </>
              ),
              title: (
                <>
                  <span className="title-with-icon">
                    <Icon name="DocumentIcon" />
                  </span>
                  Customer Consent Document
                </>
              ),
              actionBtn: (
                <>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="IcView" />}
                  />
                </>
              ),
              key: "string",
              isExpanded: true,
              id: "string",
              ariaControls: "string",
            },
          ]}
        />
      </Stack>
      <Stack
        className="collapse-wrap list-collapse"
        paddingMd={3}
        paddingXs={3}
        marginTopMd={2}
        marginTopXs={2}
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <>
                  <ComplianceDocumentContent />
                </>
              ),
              title: (
                <>
                  <span className="title-with-icon">
                    <Icon name="DocumentIcon" />
                  </span>
                  Offer of Finance
                </>
              ),
              actionBtn: (
                <>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="IcView" />}
                  />
                </>
              ),
              key: "string",
              isExpanded: false,
              id: "string",
              ariaControls: "string",
            },
          ]}
        />
      </Stack>
      <Stack
        className="collapse-wrap list-collapse"
        paddingMd={3}
        paddingXs={3}
        marginTopMd={2}
        marginTopXs={2}
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <>
                  <ComplianceDocumentContent />
                </>
              ),
              title: (
                <>
                  <span className="title-with-icon">
                    <Icon name="DocumentIcon" />
                  </span>
                  Suitability Letter
                </>
              ),
              actionBtn: (
                <>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="IcView" />}
                  />
                </>
              ),
              key: "string",
              isExpanded: false,
              id: "string",
              ariaControls: "string",
            },
          ]}
        />
      </Stack>
    </AdditionalDetailsListWrap>
  );
};

export default CompliacneDocumentList;
