import { useState, useMemo, useCallback } from "react";
import { ProposalTabs } from "@helpers/enum";
import { TProposalForm } from "@_types";
import { UseFormWatch } from "react-hook-form";
import { PROPOSAL_STEP_ITEMS_INITIAL_STATE } from "@helpers/const";
import { ProductType } from "@ntpkunity/controls-common";
import useGetProductType from "./useGetProductType";

const useProposalStepItems = ({
  watch,
}: {
  watch: UseFormWatch<TProposalForm>;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const productCode = watch("proposalDetails.productType");
  const productType = useGetProductType(productCode)
  const isAssetsTabDisabled = productType === ProductType.LOAN;
  const stepItems = useMemo(
    () =>
      PROPOSAL_STEP_ITEMS_INITIAL_STATE.map((item) => {
        if (item.title === ProposalTabs.ASSET_DETAILS)
          return { ...item, disabled: isAssetsTabDisabled };
        else return item;
      }),
    [isAssetsTabDisabled]
  );

  const getStepSize = useCallback(
    (type: "inc" | "dec") =>
      stepItems[type === "inc" ? activeIndex + 1 : activeIndex - 1].disabled
        ? 2
        : 1,
    [activeIndex, stepItems]
  );

  const getNextIndex = useCallback(() => {
    const stepSize = getStepSize("inc");
    return activeIndex + stepSize < stepItems.length
      ? activeIndex + stepSize
      : null;
  }, [activeIndex, getStepSize]);

  const getPrevIndex = useCallback(() => {
    const stepSize = getStepSize("dec");
    return activeIndex - stepSize >= 0 ? activeIndex - stepSize : null;
  }, [activeIndex, getStepSize]);

  const getNextButtonText = useCallback(() => {
    const isLastStep = activeIndex + 1 === stepItems.length;
    if (isLastStep) return "Submit Proposal";
    return `Next: ${stepItems.at(getNextIndex()).title}`;
  }, [activeIndex, getNextIndex]);

  const showPreviousStep = useCallback(() => !getPrevIndex(), [getPrevIndex]);

  const goToNextTab = useCallback(
    () => setActiveIndex(getNextIndex()),
    [getNextIndex]
  );

  const goToPrevTab = useCallback(
    () => setActiveIndex(getPrevIndex()),
    [getPrevIndex]
  );

  return {
    activeIndex,
    setActiveIndex,
    stepItems,
    goToNextTab,
    goToPrevTab,
    getNextButtonText,
    showPreviousStep,
  };
};

export default useProposalStepItems;
