import "react";
import { useTheme } from "@mui/material";
import { ComplianceDocumentWrap } from "./compliance-document.style";
import CompliacneDocumentList from "./compliance-document-list.component";

export const ComplianceDocument = () => {
  const theme = useTheme();
  return (
    <ComplianceDocumentWrap theme={theme} className="compliance-document-wrap">
      <CompliacneDocumentList />
    </ComplianceDocumentWrap>
  );
};
