import {
  useGetProductTypes,
  useGetFinanceTypes,
  useGetCompanyByTenantId,
} from "@hooks/queries";

const useProposalDetailsSetups = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();

  const companyId = companyInfo?.id;
  const productTypesQuery = useGetProductTypes();
  const financeTypesQuery = useGetFinanceTypes(companyId);

  const productTypes = productTypesQuery.isSuccess
    ? productTypesQuery.data
    : [];
  const financeTypes = financeTypesQuery.isSuccess
    ? financeTypesQuery.data
    : [];

  return {
    productTypes,
    financeTypes,
  };
};

export default useProposalDetailsSetups;
