import { useTheme } from "@mui/material";
import { FinancialConfigFieldsWrap } from "./config-fields.style";
import { Box, Grid, Select, Typography } from "@ntpkunity/controls";
import { FinancialConfigurationFee } from "@components";
import { Controller, useFormContext } from "react-hook-form";
import { FC } from "react";
import {
  AMORTIZATION_METHODS,
  APR_CALCULATION_METHODS,
  COMMISION_CALCULATION_METHODS,
  INITIAL_CASH_OUT_FLOW_FLAT_RATE_METHODS,
} from "@helpers/const";
import {
  ProductType,
  TAX_TYPES,
  TRUE_FALSE_VALUES,
} from "@ntpkunity/controls-common";

export const FinancialConfigurationFields: FC<{
  productType: ProductType;
}> = ({ productType }) => {
  const theme = useTheme();
  const { control } = useFormContext();

  return (
    <FinancialConfigFieldsWrap
      theme={theme}
      className="financial-config-fields-wrap"
    >
      <Grid theme={theme} container item rowSpacing={3} columnSpacing={2}>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${productType}.amortizationMethod`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                fullWidth
                label="Amortization Method"
                items={AMORTIZATION_METHODS}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${productType}.aprCalculationMethod`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                fullWidth
                label="APR Calculation method"
                items={APR_CALCULATION_METHODS}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${productType}.initialCashOutFlowForFlatRate`}
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                fullWidth
                label="Initial Cash Outflow for Flat Rate"
                items={INITIAL_CASH_OUT_FLOW_FLAT_RATE_METHODS}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${productType}.commissionCalculationMethod`}
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                fullWidth
                label="Commission Calculation Method"
                items={COMMISION_CALCULATION_METHODS}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${productType}.isCommissionPassToCustomer`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                fullWidth
                label="Commission Pass to Customers"
                items={TRUE_FALSE_VALUES}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${productType}.isVATApplicable`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                fullWidth
                label="VAT Applicable"
                items={TRUE_FALSE_VALUES}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6} md={1.75}>
          <Controller
            name={`${productType}.taxType`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                fullWidth
                label="Tax Type"
                items={TAX_TYPES}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Controller
            name={`${productType}.grossProfit`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
                fullWidth
                label="Calculate Gross Profit "
                items={TRUE_FALSE_VALUES}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box theme={theme} mt={3}>
        <Typography
          theme={theme}
          variant="caption"
          component={"a"}
          className="fw-medium text-primary link"
          onClick={() => {}}
        >
          Settings
        </Typography>
      </Box>
      <Box theme={theme} className="fee-container">
        <FinancialConfigurationFee productType={productType} />
      </Box>
    </FinancialConfigFieldsWrap>
    // </form>
  );
};
