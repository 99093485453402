import { useTheme } from "@mui/material";
import { FinancialConfigTabsWrap } from "./config-tabs.style";
import { ScrollableTabs } from "@ntpkunity/controls";
import { FinancialConfigurationFields } from "@components";
import { FC } from "react";
import { ProductType } from "@ntpkunity/controls-common";

export const FinancialConfigurationTabs: FC<{
  productType: ProductType;
  setProductType: React.Dispatch<React.SetStateAction<ProductType>>;
}> = ({ productType, setProductType }) => {
  const theme = useTheme();
  const handleTabChange = (newTabIndex: number) => {
    const financeTypeMap: { [key: number]: ProductType } = {
      0: ProductType.HIRE_PURCHASE,
      1: ProductType.FINANCE_LEASE,
      2: ProductType.LOAN,
    };

    setProductType(financeTypeMap[newTabIndex]);
  };
  return (
    <FinancialConfigTabsWrap
      theme={theme}
      className="financial-config-tabs-wrap"
    >
      <ScrollableTabs
        theme={theme}
        items={[
          {
            title: ProductType.HIRE_PURCHASE,
            content: (
              <>
                <FinancialConfigurationFields productType={productType} />
              </>
            ),
          },
          {
            title: ProductType.FINANCE_LEASE,
            content: (
              <>
                <FinancialConfigurationFields productType={productType} />
              </>
            ),
          },
          {
            title: ProductType.LOAN,
            content: (
              <>
                <FinancialConfigurationFields productType={productType} />
              </>
            ),
          },
        ]}
        scrollButtons={"auto"}
        defaultTabIndex={0}
        onChange={(event: any, newTabIndex: number) =>
          handleTabChange(newTabIndex)
        }
      />
    </FinancialConfigTabsWrap>
  );
};
