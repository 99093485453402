import { useTheme } from "@mui/material";
import { Button } from "@ntpkunity/controls";
import { StatusButtonProps } from "./status-buttons.props";
import clsx from "clsx";
import { StatusButtonWrap } from "./status-button.style";
import { OrderStatus } from "@helpers/enum";

export const StatusButton = ({
  status,
  bgLight,
  selected,
  onClick,
}: StatusButtonProps) => {
  const theme = useTheme();
  return (
    <StatusButtonWrap
      theme={theme}
      className={clsx({
        "status-button-wrap": true,
        "bg-light": bgLight,
        "status-primary-light": status === OrderStatus.DOCUMENT_RECEIVED,
        "status-primary-dark": status === OrderStatus.SENT_FOR_PAYOUT,
        "status-danger": [OrderStatus.WITHDRAWN, OrderStatus.DECLINED].includes(
          status
        ),
        "status-warning": status === OrderStatus.CONDITIONED,
        "status-warning-dark": status === OrderStatus.DOCUMENT_SENT,
        "status-success": status === OrderStatus.ACCEPTED,
        "status-purple": status === OrderStatus.PAID_OUT,
        "status-contract": [OrderStatus.DRAFT, OrderStatus.SUBMITTED].includes(
          status
        ),
        "status-info": status === OrderStatus.AWAITING_COMPLIANCE,
        "status-complied": status === OrderStatus.COMPLIED,
        selected: selected,
      })}
    >
      <Button
        theme={theme}
        type="button"
        defaultBtn
        text={status}
        onClick={onClick}
        startIcon={<span className="status-indicator"></span>}
      />
    </StatusButtonWrap>
  );
};
