import { useMutation, useQueryClient } from "react-query";
import { updateProposal } from "@services/dms.service";
import QUERY_KEYS from "@helpers/query-keys";

const useUpdateProposal = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateProposal,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ORDER_WORKQUEUE],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_SINGLE_PROPOSAL],
      });
    },
  });
};

export default useUpdateProposal;
