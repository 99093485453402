import "react";
import { Box, Button, DataTable, Typography } from "@ntpkunity/controls";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { DealerProposalWrap } from "./dealer-proposal-page.style";
import { DataTableWrap } from "@styles/data-table-wrap";
import { DealerProposalTableProps } from "@components/dealer-proposal/dealer-proposal-table.props";
import {
  ManualStatusChangeData,
  StatusConfirmationData,
  TProposalSearchParams,
  TProposalWorkQueueEntry,
  TSortParams,
  DiaryDialogData,
} from "@_types";
import { OrderStage, SortOrder, WorkQueueTabs } from "@helpers/enum";
import {
  APP_ROUTES,
  DEALER_QUEUE_STATUSES,
  DRAFT_QUEUE_STATUSES,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  QUEUE_TO_STATUS_MAP,
} from "@helpers/const";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDialogState } from "@hooks";
import { useDebounce } from "use-debounce";
import { convertIsoDateStrToddmmyyyy } from "@helpers/utils";
import { useSnackbarContext } from "@contexts/snackbar";
import { useGetOrders } from "@hooks/queries";
import ProposalFilter from "@components/work-queue/proposal-table/proposal-filter/proposal-filter.component";
import {
  ManualStatusChangeDialog,
  ViewConditionDialog,
} from "@components/work-queue";
import { StatusConfirmationDialog } from "@components/work-queue/status-confirmation-dialog/status-confirmation-dialog.component";
import DealerProposalTableRow from "@components/dealer-proposal/dealer-proposal-table-row/dealer-proposal-table-row.component";
import { PaginationWrap } from "@styles/pagination-wrap-style";
import TablePagination from "@mui/material/TablePagination";
import { DiaryDialog } from "@components/diary/diary-dialog/diary-dialog";

const sortInitialState: TSortParams<keyof TProposalSearchParams> = {
  sort_by: "updated_at",
  sort_order: SortOrder.DESC,
};

export const DealerProposalTable = ({
  hasCreateNew = true,
  queue,
}: DealerProposalTableProps) => {
  const theme = useTheme();
  const searchInitialState: TProposalSearchParams = {
    name: "",
    product_type: "",
    finance_amount: null,
    updated_at: "",
    order_stage: OrderStage.APPLICATION,
    customer_name: "",
    date_submitted: "",
    introducer_name: "",
    lender_name: "",
    dealer_name: "",
    updated_at_from: "",
    updated_at_to: "",
    statuses: QUEUE_TO_STATUS_MAP[queue],
    page_number: DEFAULT_PAGE_NUMBER,
    page_size: DEFAULT_PAGE_SIZE,
  };
  const navigate = useNavigate();
  const [searchParams, setSearchParams] =
    useState<TProposalSearchParams>(searchInitialState);
  const [sortParams, setSortParams] =
    useState<TSortParams<keyof TProposalSearchParams>>(sortInitialState);
  const {
    dialogOpen: statusDialogOpened,
    dialogData: statusDialogData,
    openDialog: openStatusDialog,
    closeDialog: closeStatusDialog,
  } = useDialogState<ManualStatusChangeData>();

  const {
    dialogOpen: confirmDialogOpened,
    dialogData: confirmDialogData,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog,
  } = useDialogState<StatusConfirmationData>();

  const {
    dialogOpen: conditionDialogOpened,
    openDialog: openConditionDialog,
    closeDialog: closeConditionDialog,
  } = useDialogState();

  const {
    dialogOpen: diaryDialogOpened,
    dialogData: diaryDialogData,
    openDialog: openDiaryDialog,
    closeDialog: closeDiaryDialog,
  } = useDialogState<DiaryDialogData>();

  const [debouncedSearchParams] = useDebounce(
    useMemo(() => {
      return {
        ...searchParams,
        updated_at: convertIsoDateStrToddmmyyyy(searchParams.updated_at),
        date_submitted: convertIsoDateStrToddmmyyyy(
          searchParams.date_submitted
        ),
      };
    }, [searchParams]),
    500
  );

  const { setSnackbar } = useSnackbarContext();
  const {
    isLoading,
    isError,
    data: proposals,
  } = useGetOrders<TProposalSearchParams, TProposalWorkQueueEntry>(
    debouncedSearchParams,
    sortParams
  );

  const isDealerProposal = queue === WorkQueueTabs.DEALER_PROPOSALS;
  const isNotSuccess = isLoading || isError;
  const commonFilterParams = {
    searchParams,
    setSearchParams,
    sortParams,
    setSortParams,
  };

  useEffect(() => {
    if (isError) {
      setSnackbar({
        open: true,
        message: "Error while fetching proposals.",
        variant: "error",
      });
    }
  }, [isError]);

  useEffect(() => {
    setSearchParams(searchInitialState);
    setSortParams(sortInitialState);
  }, [queue]);

  const handleChangePage = (_, newPage: number) => {
    setSearchParams({
      ...searchParams,
      page_number: newPage.toString(),
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setSearchParams({
      ...searchParams,
      page_number: DEFAULT_PAGE_NUMBER,
      page_size: event.target.value,
    });
  };

  return (
    <DealerProposalWrap theme={theme} className="add-proposal-table-wrap">
      <DataTableWrap className="table-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          theadChildren={
            <>
              <TableRow className="has-sorting">
                <TableCell>State</TableCell>
                <TableCell>Proposal Name</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Product Type</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell>
                  <ProposalFilter
                    {...commonFilterParams}
                    searchKey="statuses"
                    statuses={
                      isDealerProposal
                        ? DEALER_QUEUE_STATUSES
                        : DRAFT_QUEUE_STATUSES
                    }
                  />
                </TableCell>
                <TableCell>
                  <ProposalFilter {...commonFilterParams} searchKey="name" />
                </TableCell>
                <TableCell>
                  <ProposalFilter
                    {...commonFilterParams}
                    searchKey="customer_name"
                  />
                </TableCell>
                <TableCell>
                  <ProposalFilter
                    {...commonFilterParams}
                    searchKey="product_type"
                  />
                </TableCell>
                <TableCell>
                  <ProposalFilter
                    {...commonFilterParams}
                    searchKey="finance_amount"
                    type="number"
                  />
                </TableCell>
                <TableCell>
                  <ProposalFilter
                    {...commonFilterParams}
                    searchKey="updated_at"
                    placeholder="DD/MM/YYYY"
                  />
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {isNotSuccess || !proposals.results?.length ? (
                <TableRow>
                  <TableCell colSpan={isDealerProposal ? 6 : 7}>
                    <Typography
                      theme={theme}
                      variant="body2"
                      component="span"
                      display="block"
                      textAlign="center"
                    >
                      {isLoading
                        ? "Loading Proposals..."
                        : "No Proposals found."}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                proposals.results.map((proposal) => (
                  <DealerProposalTableRow
                    key={proposal.identifier}
                    proposal={proposal}
                    openConfirmDialog={openConfirmDialog}
                    openStatusDialog={openStatusDialog}
                    openConditionDialog={openConditionDialog}
                    openDiaryDialog={openDiaryDialog}
                  />
                ))
              )}
            </>
          }
        />
        {proposals && (
          <PaginationWrap theme={theme} className="pagination-wrap">
            <TablePagination
              component="div"
              count={proposals.total_results}
              page={parseInt(searchParams.page_number.toString())}
              onPageChange={handleChangePage}
              rowsPerPage={searchParams.page_size}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </PaginationWrap>
        )}
        {hasCreateNew && (
          <Button
            theme={theme}
            secondary
            fullWidth
            className="btn-table"
            text="Create New Proposal"
            onClick={() => navigate(APP_ROUTES.PROPOSAL)}
          />
        )}
        <ManualStatusChangeDialog
          open={statusDialogOpened}
          closeDialog={closeStatusDialog}
          dialogData={statusDialogData}
          openConfirmDialog={openConfirmDialog}
        />
        <ViewConditionDialog
          open={conditionDialogOpened}
          closeDialog={closeConditionDialog}
        />
        <StatusConfirmationDialog
          dialogData={confirmDialogData}
          open={confirmDialogOpened}
          closeDialog={closeConfirmDialog}
          closeStatusDialog={closeStatusDialog}
        />
        <DiaryDialog
          dialogData={diaryDialogData}
          open={diaryDialogOpened}
          closeDialog={closeDiaryDialog}
        />
      </DataTableWrap>
    </DealerProposalWrap>
  );
};
