import { useGetProductTypes } from "@hooks/queries";
import { useTheme } from "@mui/material";
import { Skeleton } from "@ntpkunity/controls";

export const ProductTypeCell = ({
  productTypeCode,
}: {
  productTypeCode: string;
}) => {
  const theme = useTheme();
  const { isLoading: productTypesLoading, data: productTypes } =
    useGetProductTypes();

  const productTypeName =
    productTypes.find((pt) => pt.code === productTypeCode)?.name || "";

  return productTypesLoading ? (
    <Skeleton theme={theme} height={14} />
  ) : (
    <>{productTypeName}</>
  );
};
